@import url(animate.css);
@import url(magnific-popup.css);
@import url(bootstrap-select.min.css);
@import url(jquery-ui.min.css);
a, a:focus, a:hover {
	color: #00a651
}
.list li, .topic .answer, p {
	line-height: 24px
}
.blog-sidebar .sidebar-blog-post, .shop-sidebar .sidebar-product, footer .footer-info .footer-posts .footer-blog-post {
	clear: both
}
*, body {
	font-family: Raleway, sans-serif
}
h1, h2, h3, h4, h5, h6 {
	font-family: 'Varela Round', sans-serif;
	margin: 0!important
}
h1 {
	font-size: 46px
}
h2 {
	font-size: 38px
}
h3 {
	font-size: 30px
}
h4 {
	font-size: 24px
}
h5 {
	font-size: 20px
}
h6 {
	font-size: 18px
}
p {
	font-size: 16px;
	color: #666
}
body, html {
	width: 100%;
	height: 100%
}
body.mfp-zoom-out-cur {
	overflow: hidden
}
body.boxed {
	max-width: 1200px;
	margin: 0 auto;
	background: #e3e3e3
}
section {
	position: relative;
	background: #fff
}
.overlay-black:before, .overlay-blue:before, .overlay-gradient:after, .overlay-gradient:before {
	content: '';
	position: absolute;
	left: 0;
	display: block;
	top: 0;
	overflow: hidden
}
a {
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease
}
a:focus, a:hover, a:visited {
	text-decoration: none!important;
	outline: 0!important
}
a, button, input {
	outline: 0!important
}
.form-control {
	width: 100%;
	padding: 10px 20px;
	color: #666;
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	height: auto;
	box-shadow: none;
	-webkit-appearance: none
}
.list li:before, .ripple {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%
}
.fullscreen, .overlay-black:before, .overlay-blue:before, .overlay-gradient:after {
	width: 100%;
	height: 100%
}
.form-control:focus {
	outline: 0;
	box-shadow: none;
	border-color: #e3e3e3
}
select.form-control {
	cursor: pointer;
	color: #999
}
::-webkit-input-placeholder {
opacity:1;
color:#999
}
::-moz-placeholder {
opacity:1;
color:#999
}
:-ms-input-placeholder {
opacity:1;
color:#999
}
:-moz-placeholder {
opacity:1;
color:#999
}
.uppercase {
	text-transform: uppercase
}
.capitalize, .section-title {
	text-transform: capitalize
}
ul {
	margin: 0;
	list-style: none
}
img {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-webkit-user-drag: none;
	user-drag: none
}
.shadow {
	-webkit-box-shadow: 5px 5px 30px 0 rgba(55,71,79,.1);
	-moz-box-shadow: 5px 5px 30px 0 rgba(55,71,79,.1);
	box-shadow: 5px 5px 30px 0 rgba(55,71,79,.1)
}
.shadow-hover {
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease;
	box-shadow: none
}
.shadow-hover:hover {
	-webkit-box-shadow: 5px 5px 30px 0 rgba(55,71,79,.1);
	-moz-box-shadow: 5px 5px 30px 0 rgba(55,71,79,.1);
	box-shadow: 5px 5px 30px 0 rgba(55,71,79,.1)
}
.overlay-black:before {
	background: rgba(0,0,0,.6);
	z-index: 2
}
.overlay-blue:before {
	background: rgba(41,177,253,.25);
	z-index: 2
}
.overlay-gradient:before {
	background: rgba(0,0,0,.4);
	width: 100%;
	height: 100%;
	z-index: 2
}
.overlay-gradient:after {
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: linear-gradient(to right, #00a651 -20%, #443088 120%);
	z-index: 3;
	opacity: .4
}
.overlay-black>div, .overlay-blue>div .overlay-gradient>div {
	position: relative;
	z-index: 4
}
.hover-link:after, .hover-zoom:after {
	position: absolute;
	color: #f6f6f6;
	top: 50%;
	font-family: fontawesome;
	cursor: pointer
}
.hover-link:after {
	content: '\f0c1';
	font-size: 32px;
	left: -20%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 0;
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease
}
.hover-link:hover:after {
	left: 50%;
	opacity: .9
}
.hover-zoom:after {
	content: '\f00e';
	font-size: 30px;
	left: 0;
	right: 0;
	margin: 0 auto;
	z-index: 5;
	display: block;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	text-align: center;
	opacity: 0;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-ms-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease
}
.hover-zoom:hover:after {
	opacity: .8;
	font-size: 38px
}
.transparent {
	background: 0 0!important
}
.list li:before, .section-title h2:after {
	background: #e76e34;
	content: '';
	display: block
}
.text-white {
	color: #f6f6f6
}
.text-blue {
	color: #00a651
}
.section-title {
	text-align: center;
	padding-bottom: 60px;
	margin: 0
}
.btn, section.page-header h2 {
	text-transform: uppercase
}
.section-title h2:after {
	width: 50px;
	height: 3px;
	margin: 20px auto 0
}
ul.list {
	margin-left: 0;
	padding: 0
}
.list li {
	list-style: none;
	margin: 10px 0 10px 25px;
	position: relative;
	font-size: 16px;
	color: #666
}
.list li:first-child {
	margin-top: 0
}
.list li:before {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	position: absolute;
	left: -25px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%)
}
.table {
	max-width: none
}
.nopadding {
	padding: 0!important
}
.nomargin {
	margin: 0!important
}
.ml5{
	margin-left: 5px;
}
.mr5{
	margin-right: 5px;
}
.mt5 {
	margin-top: 5px
}
.mt10 {
	margin-top: 10px
}
.mt15 {
	margin-top: 15px
}
.mt20 {
	margin-top: 20px
}
.mt25 {
	margin-top: 25px
}
.mt30 {
	margin-top: 30px
}
.mt35 {
	margin-top: 35px
}
.mt40 {
	margin-top: 40px
}
.mt45 {
	margin-top: 45px
}
.mt50 {
	margin-top: 50px
}
.mt60 {
	margin-top: 60px
}
.mt70 {
	margin-top: 70px
}
.mt80 {
	margin-top: 80px
}
.mt90 {
	margin-top: 90px
}
.mt100 {
	margin-top: 100px
}
.mt110 {
	margin-top: 110px
}
.mt120 {
	margin-top: 120px
}
.mt125 {
	margin-top: 125px
}
.mt130 {
	margin-top: 130px
}
.mt140 {
	margin-top: 140px
}
.mt150 {
	margin-top: 150px
}
.mb5 {
	margin-bottom: 5px
}
.mb10 {
	margin-bottom: 10px
}
.mb15 {
	margin-bottom: 15px
}
.mb20 {
	margin-bottom: 20px
}
.mb25 {
	margin-bottom: 25px
}
.mb30 {
	margin-bottom: 30px
}
.mb35 {
	margin-bottom: 35px
}
.mb40 {
	margin-bottom: 40px
}
.mb45 {
	margin-bottom: 45px
}
.mb50 {
	margin-bottom: 50px
}
.mb60 {
	margin-bottom: 60px
}
.mb70 {
	margin-bottom: 70px
}
.mb80 {
	margin-bottom: 80px
}
.mb90 {
	margin-bottom: 90px
}
.mb100 {
	margin-bottom: 100px
}
.mb110 {
	margin-bottom: 110px
}
.mb120 {
	margin-bottom: 120px
}
.mb125 {
	margin-bottom: 125px
}
.mb130 {
	margin-bottom: 130px
}
.mb140 {
	margin-bottom: 140px
}
.mb150 {
	margin-bottom: 150px
}
.mtb5 {
	margin: 5px 0
}
.mtb10 {
	margin: 10px 0
}
.mtb15 {
	margin: 15px 0
}
.mtb20 {
	margin: 20px 0
}
.mtb25 {
	margin: 25px 0
}
.mtb30 {
	margin: 30px 0
}
.mtb35 {
	margin: 35px 0
}
.mtb40 {
	margin: 40px 0
}
.mtb45 {
	margin: 45px 0
}
.mtb50 {
	margin: 50px 0
}
.mtb60 {
	margin: 60px 0
}
.mtb70 {
	margin: 70px 0
}
.mtb80 {
	margin: 80px 0
}
.mtb90 {
	margin: 90px 0
}
.mtb100 {
	margin: 100px 0
}
.mtb110 {
	margin: 110px 0
}
.mtb120 {
	margin: 120px 0
}
.mtb130 {
	margin: 130px 0
}
.mtb140 {
	margin: 140px 0
}
.mtb150 {
	margin: 150px 0
}
.p0 {
	padding: 0px
}
.pl0{
	padding-left: 0px;
}
.pr0{
	padding-right: 0px;
}
.pt5 {
	padding-top: 5px
}
.pt10 {
	padding-top: 10px
}
.pt15 {
	padding-top: 15px
}
.pt20 {
	padding-top: 20px
}
.pt25 {
	padding-top: 25px
}
.pt30 {
	padding-top: 30px
}
.pt35 {
	padding-top: 35px
}
.pt40 {
	padding-top: 40px
}
.pt45 {
	padding-top: 45px
}
.pt50 {
	padding-top: 50px
}
.pt60 {
	padding-top: 60px
}
.pt70 {
	padding-top: 70px
}
.pt80 {
	padding-top: 80px
}
.pt90 {
	padding-top: 90px
}
.pt100 {
	padding-top: 100px
}
.pt110 {
	padding-top: 110px
}
.pt120 {
	padding-top: 120px
}
.pt130 {
	padding-top: 130px
}
.pt140 {
	padding-top: 140px
}
.pt150 {
	padding-top: 150px
}
.pt160 {
	padding-top: 160px
}
.pb5 {
	padding-bottom: 5px
}
.pb10 {
	padding-bottom: 10px
}
.pb15 {
	padding-bottom: 15px
}
.pb20 {
	padding-bottom: 20px
}
.pb25 {
	padding-bottom: 25px
}
.pb30 {
	padding-bottom: 30px
}
.pb35 {
	padding-bottom: 35px
}
.pb40 {
	padding-bottom: 40px
}
.pb45 {
	padding-bottom: 45px
}
.pb50 {
	padding-bottom: 50px
}
.pb60 {
	padding-bottom: 60px
}
.pb70 {
	padding-bottom: 70px
}
.pb80 {
	padding-bottom: 80px
}
.pb90 {
	padding-bottom: 90px
}
.pb100 {
	padding-bottom: 100px
}
.pb110 {
	padding-bottom: 110px
}
.pb120 {
	padding-bottom: 120px
}
.pb130 {
	padding-bottom: 130px
}
.pb140 {
	padding-bottom: 140px
}
.pb150 {
	padding-bottom: 150px
}
.pb160 {
	padding-bottom: 160px
}
.ptb5 {
	padding: 5px 0
}
.ptb10 {
	padding: 10px 0
}
.ptb15 {
	padding: 15px 0
}
.ptb20 {
	padding: 20px 0
}
.ptb25 {
	padding: 25px 0
}
.ptb30 {
	padding: 30px 0
}
.ptb35 {
	padding: 35px 0
}
.ptb40 {
	padding: 40px 0
}
.ptb45 {
	padding: 45px 0
}
.ptb50 {
	padding: 50px 0
}
.ptb60 {
	padding: 60px 0
}
.ptb70 {
	padding: 70px 0
}
.ptb80 {
	padding: 80px 0
}
.ptb90 {
	padding: 90px 0
}
.ptb100 {
	padding: 100px 0
}
.ptb110 {
	padding: 110px 0
}
.ptb120 {
	padding: 120px 0
}
.ptb130 {
	padding: 130px 0
}
.ptb140 {
	padding: 140px 0
}
.ptb150 {
	padding: 150px 0
}
.ptb160 {
	padding: 160px 0
}
.p10{padding: 10px 0;}
.btn {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	outline: 0;
	padding: 0 15px;
	position: relative;
	line-height: 35px;
	font-size: 14px;
	font-weight: 600;
	color: #f6f6f6!important;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-ms-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease
}
.modal-footer button.btn[data-bb-handler="cancel"]{color: #333!important;}
.back-top i, .btn:focus {
	color: #f6f6f6
}
.back-top, footer .footer-info .footer-posts .thumbnail-post img {
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease
}
.btn.active, .btn:active {
	box-shadow: none!important
}
.btn-small {
	line-height: 25px
}
.btn-large {
	line-height: 50px;
	font-size: 16px
}
.btn-effect {
	overflow: hidden
}
.ripple {
	width: 0;
	height: 0;
	border-radius: 50%;
	background: rgba(255,255,255,.4);
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	position: absolute;
	opacity: 1
}
.rippleEffect {
	animation: rippleDrop .6s linear
}
@keyframes rippleDrop {
100% {
-webkit-transform:scale(2.5);
-moz-transform:scale(2.5);
-o-transform:scale(2.5);
-ms-transform:scale(2.5);
transform:scale(2.5);
opacity:0
}
}
.btn-blue {
	background: #e76e34
}
.btn-blue:hover {
	background: #e76e34d6
}
.btn-green {
	background: #2ecc71
}
.btn-green:hover {
	background: #4dd977
}
.btn-purple {
	background: #443088
}
.btn-purple:hover {
	background: #5c48a0
}
.btn-orange {
	background: #e69d29
}
.btn-orange:hover {
	background: #ecaf4f
}
.btn-red {
	background: #db6131
}
.btn-red:hover {
	background: #dc7f5a
}
.btn-dark {
	background: #0c112a
}
.btn-dark:hover {
	background: #293053
}
.back-top {
	position: fixed;
	opacity: 0;
	right: 0;
	bottom: 30px;
	height: 45px;
	width: 45px;
	text-align: center;
	line-height: 45px;
	background: -moz-linear-gradient(left, #00a651 -30%, #026c36 130%);
	background: -webkit-linear-gradient(left, #00a651 -30%, #026c36 130%);
	background: linear-gradient(to right, #00a651 -30%, #026c36 130%);
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	box-shadow: 0 0 50px 0 rgba(68,48,136,.5);
	font-size: 18px;
	z-index: 10;
	visibility: hidden;
	transition: all .6s ease
}
.back-top:hover {
	box-shadow: 0 0 0 0 rgba(68,48,136,0)
}
.back-top-visible {
	opacity: 1;
	right: 30px;
	visibility: visible
}
[type=checkbox]:checked, [type=checkbox]:not(:checked) {
	position: absolute;
	left: -9999px
}
[type=checkbox]:checked+label, [type=checkbox]:not(:checked)+label {
	position: relative;
	padding-left: 25px;
	cursor: pointer
}
[type=checkbox]:checked+label:before, [type=checkbox]:not(:checked)+label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 1.25em;
	height: 1.25em;
	border: 2px solid #e3e3e3;
	background: #fff;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px
}
[type=checkbox]:checked+label:after, [type=checkbox]:not(:checked)+label:after {
	content: '✔';
	position: absolute;
	top: 0;
	left: 3px;
	font-size: 18px;
	line-height: 1;
	color: #e76e34;
	-webkit-transition: all .2s;
	-moz-transition: all .2s;
	-ms-transition: all .2s;
	-o-transition: all .2s;
	transition: all .2s
}
[type=checkbox]:not(:checked)+label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0)
}
[type=checkbox]:checked+label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1)
}
#main-nav .navbar-nav .simple-menu .dropdown-menu, .social-btn-roll:hover .social-btn-roll-icons {
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%)
}
.social-btn-roll {
	width: 35px;
	height: 35px;
	border: 1px solid #3b5998;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	overflow: hidden;
	display: block;
	-webkit-transition: background .3s ease;
	-moz-transition: background .3s ease;
	-ms-transition: background .3s ease;
	-o-transition: background .3s ease;
	transition: background .3s ease
}
.social-btn-roll:hover .social-btn-roll-icons {
	transform: translateX(-50%)
}
.social-btn-roll:hover .social-btn-roll-icon {
	color: #f6f6f6
}
.social-btn-roll-icons {
	width: 200%;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease
}
.social-btn-roll-icon {
	width: 50%;
	line-height: 35px;
	text-align: center;
	display: block;
	float: left;
	font-size:24px;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-ms-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s
}
.social-btn-roll.facebook {
	border-color: #3b5998;
	color: #3b5998
}
.social-btn-roll.facebook:hover {
	background: #3b5998
}
.social-btn-roll.twitter {
	border-color: #4cc4f2;
	color: #4cc4f2
}
.social-btn-roll.twitter:hover {
	background: #4cc4f2
}
.social-btn-roll.google-plus {
	border-color: #dd4b39;
	color: #dd4b39
}
.social-btn-roll.google-plus:hover {
	background: #dd4b39
}
.social-btn-roll.instagram {
	border-color: #9b6954;
	color: #9b6954
}
.social-btn-roll.instagram:hover {
	background: #9b6954
}
.social-btn-roll.linkedin {
	border-color: #0077b5;
	color: #0077b5
}
.social-btn-roll.linkedin:hover {
	background: #0077b5
}
.social-btn-roll.rss {
	border-color: orange;
	color: orange
}
.social-btn-roll.rss:hover {
	background: orange
}
.social-btn-roll.pinterest {
	border-color: #cb2027;
	color: #cb2027
}
.social-btn-roll.pinterest:hover {
	background: #cb2027
}
.social-btn-roll.tumblr {
	border-color: #32506d;
	color: #32506d
}
.social-btn-roll.tumblr:hover {
	background: #32506d
}
.social-btn-roll.github {
	border-color: #171515;
	color: #171515
}
.social-btn-roll.github:hover {
	background: #171515
}
.social-btn-roll.dribbble {
	border-color: #ec4a89;
	color: #ec4a89
}
.social-btn-roll.dribbble:hover {
	background: #ec4a89
}
section.page-header {
	position: relative;
	/*background: url(../images/img/page-header.jpg) center center no-repeat;*/
	background-size: cover;
	text-align: center;
	padding: 40px 0;
	margin: 0;
	border: 0;
	color: #f6f6f6
}
section.page-header2, section.page-header3 {
	border-top: 1px solid #e3e3e3;
	border-bottom: 1px solid #e3e3e3
}
section.page-header>div {
	z-index: 4;
	position: relative
}
section.page-header:after, section.page-header:before {
	width: 100%;
	height: 100%;
	display: block;
	content: '';
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0
}
section.page-header:before {
	background: rgba(0,0,0,.6);
	z-index: 2
}
section.page-header:after {
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: linear-gradient(to right, #00a651 -20%, #443088 120%);
	z-index: 3;
	opacity: .2
}
section.page-header .breadcrumb {
	padding: 0;
	margin: 0;
	background: 0 0;
	text-transform: capitalize
}
section.page-header .breadcrumb>li a {
	color: #f6f6f6
}
section.page-header .breadcrumb>.active {
	color: #999
}
section.page-header .breadcrumb>li+li:before {
	padding: 0 7px;
	color: inherit
}
section.page-header2 {
	padding: 40px 0;
	background: #f0f0f0
}
section.page-header2 h2 {
	text-transform: uppercase;
	color: #666
}
section.page-header2 .breadcrumb {
	background: 0 0;
	text-transform: capitalize;
	font-weight: 700;
	line-height: 41px;
	padding: 0;
	text-align: right;
	margin: 0
}
section.page-header3 {
	padding: 40px 0;
	background: #1e1f21
}
section.page-header3 h2 {
	text-transform: uppercase;
	color: #f6f6f6
}
section.page-header3 .breadcrumb {
	background: 0 0;
	text-transform: capitalize;
	font-weight: 700;
	line-height: 41px;
	padding: 0;
	text-align: right;
	margin: 0
}
section.page-header3 .breadcrumb>.active {
	color: #999
}
section.page-header4 {
	position: relative;
	/*background: url(../images/img/page-header.jpg) center center no-repeat fixed;*/
	background-size: cover;
	text-align: center;
	padding: 60px 0;
	margin: 0;
	border: 0;
	color: #f6f6f6
}
section.page-header4>div {
	z-index: 4;
	position: relative
}
section.page-header4:after, section.page-header4:before {
	width: 100%;
	height: 100%;
	content: '';
	overflow: hidden;
	position: absolute;
	top: 0;
	display: block;
	left: 0
}
section.page-header4:before {
	background: rgba(0,0,0,.6);
	z-index: 2
}
section.page-header4:after {
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: linear-gradient(to right, #00a651 -20%, #443088 120%);
	z-index: 3;
	opacity: .2
}
section.page-header4 h2 {
	text-transform: uppercase
}
section.page-header4 .breadcrumb {
	padding: 0;
	margin: 0;
	background: 0 0;
	text-transform: capitalize
}
section.page-header4 .breadcrumb>li a {
	color: #f6f6f6
}
section.page-header4 .breadcrumb>.active {
	color: #999
}
section.page-header4 .breadcrumb>li+li:before {
	padding: 0 7px;
	color: inherit
}
section.pricing-tables {
	background: #f0f0f0
}
.pricing-table {
	position: relative;
	text-align: center;
	background: #fff;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 40px;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease
}
.pricing-table:hover {
	margin-top: -10px
}
#popular.pricing-table:before {
	content: '\f005';
	font-family: fontawesome;
	width: 50px;
	height: 50px;
	display: block;
	position: absolute;
	color: #fff;
	text-align: center;
	top: -25px;
	right: 0;
	left: 0;
	margin: 0 auto;
	background: #00a651;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	font-size: 24px;
	line-height: 50px
}
#main-nav .navbar-nav .dropdown-menu>li>a, .navbar .navbar-nav>li>a, footer .copyright a, footer .copyright span {
	font-family: 'Varela Round', sans-serif
}
.pricing-table .pricing-header {
	text-transform: capitalize
}
.pricing-table .pricing {
	margin: 40px 0;
	padding: 20px 0;
	border-top: 1px solid #e3e3e3;
	border-bottom: 1px solid #e3e3e3;
	color: #555
}
.pricing-table .pricing .currency {
	font-size: 18px;
	font-weight: 700
}
.pricing-table .pricing .amount {
	font-size: 52px;
	font-weight: 700;
	vertical-align: middle
}
.pricing-table .pricing .month {
	text-transform: capitalize;
	vertical-align: bottom;
	line-height: 40px;
	font-weight: 600
}
.pricing-table .pricing-footer {
	margin-top: 40px
}
header {
	width: 100%;
	z-index: 999;
	-webkit-transition: .6s all ease;
	-moz-transition: .6s all ease;
	-ms-transition: .6s all ease;
	-o-transition: .6s all ease;
	transition: .6s all ease
}
header.navbar-fixed-top {
	position: fixed!important
}
nav.navbar {
	background: #f6f6f6;
	border: none;
	padding: 5px 0;
	margin: 0
}
.navbar-brand {
	height: 100%;
	padding:5px 0;
	width:100%;
}
.navbar-brand img {
	cursor: pointer;
	width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
	/* background-image:'../../image/images/header-logo.svg'; */
    /*width: 65%;*/
}
/*.navbar-brand img {
	height: 30px;
	cursor: pointer
}*/
div#main-nav {
	padding-top: 25px
}
.navbar .navbar-nav>li.mobile-title {
	display: none
}
.navbar .navbar-nav>li.login-btn {
	margin-left: 10px
}
.nav>li>a:focus, .nav>li>a:hover {
background:0 0!important
}
.nav>li>a:focus {
	color: #00a651!important
}
.navbar .navbar-nav>li>a {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 600;
	color: #666;
	padding: 5px 10px
}
.navbar .navbar-nav>li>a:hover {
	color: #00a651;
	-webkit-transition: .3s ease-in;
	-moz-transition: .3s ease-in;
	-ms-transition: .3s ease-in;
	-o-transition: .3s ease-in;
	transition: .3s ease-in
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
	background: 0 0!important;
	color: #00a651
}
.navbar .navbar-nav>li>a i {
	padding-left: 5px
}
.navbar .navbar-nav>li:last-child>a i {
	padding-right: 5px;
	padding-left: 0
}
.navbar .navbar-nav>li.login-btn>a {
	background: #00a651;
	color: #f6f6f6!important;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
.header2 nav {
	padding: 20px 0
}
.header2 a.navbar-brand {
	float: none
}
.header2 .navbar-brand img {
	margin: 0 auto
}
.header2 .navbar .navbar-nav {
	display: inline-block;
	float: none;
	vertical-align: top
}
.header2 .navbar .navbar-collapse {
	text-align: center
}
.header3 .top-bar {
	background: #f0f0f0;
	padding: 10px 0
}
.header3 .top-bar span {
	line-height: 40px;
	color: #999
}
.header3 .top-bar .social-btns li a {
	-webkit-transform: scale(.9);
	-moz-transform: scale(.9);
	-ms-transform: scale(.9);
	-o-transform: scale(.9);
	transform: scale(.9)
}
header.sticky {
	position: fixed;
	top: 0
}
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus {
	color: #f6f6f6;
	background: 0 0
}
.navbar-default .navbar-nav>.open>a:hover {
	color: #00a651;
	background: 0 0
}
#main-nav .navbar-nav .dropdown .dropdown-menu {
	background: #fff;
	padding: 10px 0;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	border-top: solid 3px #e76e34;
	display: block;
	visibility: hidden;
	min-width: 250px;
	margin-top: 25px;
	-webkit-transition: all .2s ease;
	-moz-transition: all .2s ease;
	-ms-transition: all .2s ease;
	-o-transition: all .2s ease;
	transition: all .2s ease;
	-webkit-box-shadow: 0 0 15px 5px rgba(30,31,33,.15);
	-moz-box-shadow: 0 0 15px 5px rgba(30,31,33,.15);
	box-shadow: 0 0 15px 5px rgba(30,31,33,.15);
	z-index: 99;
	opacity: 0
}
#main-nav .navbar-nav .dropdown.open .dropdown-menu {
	margin-top: 0;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-ms-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
	visibility: visible
}
#main-nav .navbar-nav .open>.dropdown-menu {
	opacity: 1
}
#main-nav .navbar-nav .open a {
	color: #e76e34
}
#main-nav .navbar-nav .dropdown-menu>li>a {
	padding: 5px 20px;
	font-weight: 600;
	color: #666;
	text-transform: capitalize
}
#main-nav .navbar-nav .dropdown-menu>li>a:focus, #main-nav .navbar-nav .dropdown-menu>li>a:hover {
	background: 0 0;
	color: #29b1df
}
#main-nav .navbar-nav .dropdown-menu>li>a i {
	position: absolute;
	right: 15px
}
#main-nav .navbar-nav .simple-menu .dropdown-menu {
	transform: translateX(-50%);
	min-width: 220px;
	max-width: 250px;
	left: 50%
}
/*BY SJ max-width 250px"*/
#main-nav .navbar-nav .simple-menu.open .dropdown-menu {
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%)
}
#main-nav .navbar-nav .simple-menu .dropdown-menu>.dropdown-submenu {
	position: relative
}
#main-nav .navbar-nav .dropdown-menu>.dropdown-submenu>.dropdown-menu {
	top: -13px;
	left: 130%;
	visibility: hidden;
	-webkit-transition: all 0s ease;
	-moz-transition: all 0s ease;
	-ms-transition: all 0s ease;
	-o-transition: all 0s ease;
	transition: all 0s ease
}
#main-nav .navbar-nav .dropdown-menu>.dropdown-submenu.open>.dropdown-menu {
	left: 150%;
	visibility: visible;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-ms-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease
}
#main-nav .navbar-nav .dropdown-menu>.dropdown-submenu>.dropdown-menu.left {
	left: -30%
}
#main-nav .navbar-nav .dropdown-menu>.dropdown-submenu.open>.dropdown-menu.left {
	left: -50%
}
#main-nav .navbar-nav .mega-menu {
	position: static!important
}
#main-nav .navbar-nav .mega-menu .dropdown-menu {
	width: 80%;
	padding: 10px 0
}
#main-nav .navbar-nav .mega-menu .dropdown-menu li {
	padding: 0 20px
}
#main-nav .navbar-nav .mega-menu .dropdown-menu .mega-menu-inner {
	padding: 10px 5px
}
#main-nav .navbar-nav .mega-menu .dropdown-menu .mega-menu-inner ul li {
	font-family: 'Varela Round', sans-serif;
	padding: 5px 0
}
#main-nav .navbar-nav .mega-menu .dropdown-menu .mega-menu-inner ul li:first-child {
	border-bottom: 1px solid #ddd
}
#main-nav .navbar-nav .mega-menu .dropdown-menu .mega-menu-inner ul li.menu-title {
	color: #333;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 10px
}
#main-nav .navbar-nav .mega-menu .dropdown-menu .mega-menu-inner ul li a {
	color: #666;
	text-transform: capitalize;
	font-weight: 600
}
#main-nav .navbar-nav .mega-menu .dropdown-menu .mega-menu-inner ul li a:hover {
	color: #e76e34
}
.cd-user-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.65);
	z-index: 1039;
	overflow-y: auto;
	cursor: pointer;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: .5s ease;
	-moz-transition: .5s ease;
	-ms-transition: .5s ease;
	-o-transition: .5s ease;
	transition: .5s ease
}
.cd-user-modal.is-visible {
	visibility: visible;
	opacity: 1
}
.cd-user-modal.is-visible .cd-user-modal-container {
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0)
}
.cd-user-modal-container {
	position: relative;
	width: 90%;
	max-width: 600px;
	background: #FFF;
	margin: 10% auto 0;
	cursor: auto;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-webkit-transform: translateY(-70px);
	-moz-transform: translateY(-70px);
	-ms-transform: translateY(-70px);
	-o-transform: translateY(-70px);
	transform: translateY(-70px);
	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	-ms-transition-property: -moz-transform;
	-o-transition-property: -moz-transform;
	transition-property: transform;
	-webkit-transition-duration: .8s;
	-moz-transition-duration: .8s;
	-ms-transition-duration: .8s;
	-o-transition-duration: .8s;
	transition-duration: .8s
}
.cd-user-modal-container .cd-switcher {
	list-style: none;
	padding: 0
}
.cd-user-modal-container .cd-switcher:after {
	content: "";
	display: table;
	clear: both
}
.cd-user-modal-container .cd-switcher li {
	width: 50%;
	float: left;
	text-align: center
}
.cd-user-modal-container .cd-switcher a {
	display: block;
	width: 100%;
	height: 50px;
	line-height: 50px;
	font-weight: 600;
	background: #00a651;
	color: #fff;
	font-size: 18px;
	text-transform: uppercase;
	text-decoration: none;
	-webkit-transition: .3s ease;
	-moz-transition: .3s ease;
	-ms-transition: .3s ease;
	-o-transition: .3s ease;
	transition: .3s ease
}
.cd-user-modal-container .cd-switcher a:hover {
	background: #443078
}
.cd-user-modal-container .cd-switcher a.selected {
	background: #fff;
	color: #323232
}
.bootstrap-select.btn-group .dropdown-menu li.selected.active a {
    background: 0 0;
    color: #e76e34;
    font-weight: 600;
}
.bootstrap-select.btn-group .dropdown-menu li.selected.active a:hover {
    background: 0 0;
    color: #e76e34d6;
    font-weight: 600;
}
@media only screen and (min-width:600px) {
.cd-user-modal-container .cd-switcher a {
	height: 70px;
	line-height: 70px
}
}
.cd-form {
	padding: 1.4em
}
.cd-form .fieldset {
	position: relative;
	margin: 1.4em 0
}
.cd-form .fieldset:first-child {
	margin-top: 0
}
.cd-form .fieldset:last-child {
	margin-bottom: 0
}
.cd-form label {
	font-size: 14px;
	color: #444;
	font-weight: 400
}
.cd-form label.image-replace {
	display: inline-block;
	position: absolute;
	left: 15px;
	top: 50%;
	bottom: auto;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	height: 20px;
	width: 20px;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	color: transparent;
	text-shadow: none;
	background-repeat: no-repeat;
	background-position: 50% 0
}
.cd-form input {
	margin: 0;
	padding: 0;
	border-radius: .25em
}
.cd-form input.full-width {
	width: 100%
}
.cd-form input.has-padding {
	padding: 12px 20px
}
.cd-form input.has-border {
	border: 1px solid #d2d8d8;
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none
}
.cd-form input.has-border:focus {
	border-color: #00a651;
	outline: 0
}
.cd-form input.has-error {
	border: 1px solid #d76666
}
.cd-form input[type=password] {
	padding-right: 65px
}
.cd-form #remember-me {
	border: none;
	cursor: pointer;
	position: relative
}
.cd-form #remember-me:checked {
	background: #00a651;
	color: #f6f6f6
}
.cd-form input[type=submit]:hover {
	background: #5bbc2e
}
form.cd-form button {
	/*job-list*/
	display: block;
	margin: 0 auto;
	color: #323232
}
@media only screen and (min-width:600px) {
.cd-form {
	padding: 2em
}
.cd-form .fieldset:first-child {
	margin-top: 0
}
.cd-form .fieldset:last-child {
	margin-bottom: 0
}
.cd-form input.has-padding {
	padding: 16px 20px
}
.cd-form input[type=submit] {
	padding: 16px 0
}
}
.cd-form-message {
	padding: 10px 15px 0;
	font-size: 16px;
	line-height: 1.4;
	text-align: justify
}
.cd-form-bottom-message {
	position: absolute;
	width: 100%;
	text-align: center;
	font-size: 16px;
	margin-top: 10px
}
.cd-form-bottom-message a {
	color: #FFF;
	text-decoration: none
}
.cd-form-bottom-message a:hover {
	text-decoration: none;
	color: #f90
}
#cd-login, #cd-reset-password, #cd-signup {
	display: none
}
#cd-login.is-selected, #cd-reset-password.is-selected, #cd-signup.is-selected {
	display: block
}
footer {
	position: relative
}
footer .footer-info {
	position: relative;
	/*background: url(../images/img/footer-bg.jpg) no-repeat;*/
	background-size: cover
}
footer .footer-info:before {
	background: rgba(0,0,0,.8);
	width: 100%;
	height: 100%;
	display: block;
	content: '';
	overflow: hidden;
	z-index: 2;
	position: absolute;
	top: 0;
	left: 0
}
footer .footer-info>div {
	position: relative;
	z-index: 3
}
footer .footer-info h3 {
	color: #f6f6f6;
	text-transform: capitalize;
	padding-bottom: 40px
}
footer .footer-info a, footer .footer-info p, footer .footer-info span, footer .footer-info ul li {
	font-size: 14px;
	line-height: 22px;
	color: #999
}
footer .footer-info .footer-about {
	font-size: 14px
}
footer .footer-info .footer-about img {
	height: auto;/*height:33px;*/
}
footer .footer-info .footer-about ul li {
	padding: 20px 0;
	border-bottom: 1px solid rgba(153,153,153,.2)
}
footer .footer-info .footer-about ul li:last-child {
	padding-bottom: 0;
	border: 0
}
footer .footer-info .footer-about ul li i {
	padding: 0 15px
}
footer .footer-info .footer-links ul li {
	padding-bottom: 10px
}
footer .footer-info .footer-links ul li:last-child {
	padding-bottom: 0
}
footer .footer-info .footer-links a {
	text-transform: capitalize
}
footer .footer-info .footer-links a:hover {
	color: #e76e34;
	padding-left: 10px
}
footer .footer-info .footer-links a i {
	padding-right: 10px
}
footer .footer-info .footer-posts .thumbnail-post {
	width: 80px;
	height: 80px;
	overflow: hidden;
	position: relative;
	float: left;
	margin-right: 20px;
	margin-bottom: 30px;
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: linear-gradient(to right, #00a651 -20%, #443088 120%)
}
footer .footer-info .footer-posts .thumbnail-post img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all .6s ease
}
form.mailchimp label, section#categories .category .category-icon {
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease
}
footer .footer-info .footer-posts .thumbnail-post img:hover {
	opacity: .5
}
footer .footer-info .footer-posts .post-info {
	padding-top: 15px;
	float: left
}
footer .footer-info .footer-posts .post-info a {
	text-transform: capitalize;
	font-weight: 600;
	color: #f6f6f6;
	display: block
}
footer .footer-info .footer-posts .post-info a:hover {
	color: #00a651
}
footer .footer-info .footer-posts .post-info span {
	font-style: italic
}
footer .footer-info .footer-newsletter .form-group, footer .footer-info .footer-newsletter .form-group .input-group {
	width: 100%
}
footer .footer-info .footer-newsletter input.form-control {
	width: 70%;
	height: 40px;
	background: rgba(237,237,237,.3);
	border: 0;
	-webkit-border-radius: 5px 0 0 5px;
	-moz-border-radius: 5px 0 0 5px;
	border-radius: 5px 0 0 5px;
	color: #999;
	padding-left: 20px;
	box-shadow: none
}
footer .footer-info .footer-newsletter button {
	width: 30%;
	height: 40px;
	line-height: 40px;
	-webkit-border-radius: 0 5px 5px 0;
	-moz-border-radius: 0 5px 5px 0;
	border-radius: 0 5px 5px 0;
	padding: 0
}
form.mailchimp label {
	position: fixed;
	left: 0;
	bottom: -50px;
	width: 100%;
	color: #f6f6f6;
	text-align: center;
	padding: 15px 0;
	margin: 0;
	z-index: 10;
	opacity: 0;
	visibility: hidden;
	transition: all .6s ease;
	-webkit-transform: translateZ(0)
}
.slider-content, section.main .wrapper {
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	top: 50%
}
form.mailchimp label.error, form.mailchimp label.valid {
	bottom: 0;
	opacity: 1;
	visibility: visible
}
form.mailchimp label.valid {
	background: #2ecc71
}
form.mailchimp label.error {
	background: #dc464a
}
footer .footer-info .footer-newsletter .footer-chat {
	background: #e76e34;
	padding: 20px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px
}
footer .footer-info .footer-newsletter .footer-chat>div {
	padding: 0
}
footer .footer-info .footer-newsletter .supporter-image {
	width: 70px;
	height: 70px;
	margin: 0 auto;
	display: block
}
.footer2 .footer-info:before, .footer3 .footer-info:before, .job-search-form label, section#latest-news .blog-post-ver2 .post-info:after, section#latest-news .blog-post-ver2 .post-info:before {
	display: none
}
footer .footer-info .footer-newsletter .supporter-image img {
	width: 100%
}
footer .footer-info .footer-newsletter .chat-details {
	padding-left: 15px
}
footer .footer-info .footer-newsletter .chat-details span {
	color: #f6f6f6;
	font-weight: 600
}
footer .footer-info .footer-newsletter .chat-details a, footer .footer-info .footer-newsletter .chat-details p {
	color: #f6f6f6
}
footer .footer-info .footer-newsletter .chat-details a:hover {
	text-decoration: underline!important
}
footer .footer-info .footer-newsletter .chat-details a i {
	padding-right: 10px
}
footer .copyright {
	background: #f6f6f6
}
footer .copyright .container {
	text-align: center;
}
footer .copyright span {
	color: #999;
	line-height: 40px
}
footer .copyright a, footer .copyright span {
	font-weight: 600;
	text-transform: capitalize
}
footer .copyright .social-btn-roll {
	border: 1px solid transparent;
	background: 0 0;
	color: #999
}
.footer2, .footer2 .copyright {
	border-top: 1px solid #e3e3e3
}
.footer2 .footer-info {
	background: #f0f0f0
}
.footer2 .footer-info a, .footer2 .footer-info h3, .footer2 .footer-info p, .footer2 .footer-info span, .footer2 .footer-info ul li {
	color: #666
}
.footer2 .footer-info .footer-posts .post-info a {
	color: #323232
}
.footer2 .footer-info .footer-posts .post-info a:hover {
	color: #e76e34
}
.footer2 .footer-info .footer-newsletter input.form-control {
	background: #fff
}
.footer2 .copyright {
	background: #f0f0f0
}
.footer3 .footer-info {
	background: #1e1f21
}
.footer3 .copyright {
	border-top: 1px solid #323232
}
.footer4 .footer-info .footer-contact ul li {
	padding: 20px 0;
	border-bottom: 1px solid rgba(153,153,153,.2)
}
.footer4 .footer-info .footer-contact ul li:first-child {
	padding-top: 0
}
.footer4 .footer-info .footer-contact ul li:last-child {
	padding-bottom: 0;
	border: 0
}
.footer4 .footer-info .footer-contact ul li i {
	padding: 0 15px
}
section.main {
	/*background: url(../images/img/image1.jpg) center center no-repeat;*/
	background-size: cover;
	height: 555px;
}
section.main .wrapper {
	position: relative;
	z-index: 3;
	transform: translateY(-50%)
}
section.main .wrapper h1 {
	font-size: 55px
}
.job-search-form input {
	height: 50px;
	width: 100%;
	border: none;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding-left: 30px;
	font-size: 18px;
	color: #999
}
.job-search-form .search-location:before {
	content: '\f276';
	font-family: fontawesome;
	font-size: 22px;
	position: absolute;
	right: 30px;
	color: #999;
	line-height: 50px
}
.job-search-form .search-submit button {
	width: 100%;
	margin: 0
}
.job-search-form .search-submit button i {
	margin-right: 7px
}
.extra-info a, .extra-info span {
	font-size: 18px
}
section.main2 {
	height: 500px
}
.swiper-container {
	height: 100%
}
.slider-content {
	position: relative;
	z-index: 15;
	transform: translateY(-50%)
}
.swiper-button-next, .swiper-button-prev, section#categories2 .cat-wrapper ul li:before {
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%)
}
section.main3 {
	/*background: url(../images/img/image3.jpg) center center no-repeat;*/
	background-size: cover
}
section.main3>div {
	position: relative;
	z-index: 4
}
section.main3 .job-search-form {
	background: #fff;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	overflow: hidden
}
section.main3 .job-search-form .form-title {
	padding: 15px 0;
	margin-bottom: 30px;
	background: #f0f0f0;
	text-align: center
}
section.main3 .job-search-form .form-title h4 {
	text-transform: uppercase;
	color: #666
}
section#categories .category .category-info a, section#categories .category h4, section#categories2 .cat-wrapper ul li a {
	text-transform: capitalize
}
section.main3 .job-search-form .search-categories .bootstrap-select button, section.main3 .job-search-form .search-keywords input, section.main3 .job-search-form .search-location input {
	border: 1px solid #e3e3e3;
	box-shadow: none!important;
	-webkit-appearance: none
}
section.main3 .job-search-form .search-categories, section.main3 .job-search-form .search-keywords, section.main3 .job-search-form .search-location, section.main3 .job-search-form .search-submit {
	padding: 0 30px
}
section.main3 .job-search-form .search-location:before {
	right: 40px
}
.swiper-button-next, .swiper-button-prev {
	top: 50%;
	transform: translateY(-50%);
	width: 45px;
	height: 45px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	background: rgba(30,31,33,.3);
	text-align: center
}
.swiper-container .swiper-button-next i, .swiper-container .swiper-button-prev i {
	font-size: 30px;
	color: #f6f6f6;
	line-height: 45px
}
section#categories .category {
	position: relative;
	text-align: center;
	background: #f0f0f0;
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	height: 250px
}
section#categories .category span {
	font-style: italic
}
section#categories .category .category-icon {
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	transition: all .6s ease
}
section#categories .category .category-icon i {
	width: 100%;
	font-size: 70px;
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease
}
section#categories .category .category-info {
	position: relative;
	top: 0;
	-webkit-transition: all .6s ease 0s;
	-moz-transition: all .6s ease 0s;
	-ms-transition: all .6s ease 0s;
	-o-transition: all .6s ease 0s;
	transition: all .6s ease 0s
}
section#categories .category .category-info a {
	font-family: 'Varela Round', sans-serif;
	font-size: 24px;
	color: #2e2e2e
}
section#categories .category .category-info p {
	opacity: 1;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease
}
section#categories .category .category-descr {
	position: absolute;
	top: 35%;
	opacity: 0;
	padding: 10px 30px;
	visibility: hidden;
	-webkit-transition: all .3s ease 0s;
	-moz-transition: all .3s ease 0s;
	-ms-transition: all .3s ease 0s;
	-o-transition: all .3s ease 0s;
	transition: all .3s ease 0s;
	-webkit-transform: scale(1.5);
	-moz-transform: scale(1.5);
	-ms-transform: scale(1.5);
	-o-transform: scale(1.5);
	transform: scale(1.5)
}
section#categories .category:hover .category-icon {
	-webkit-transform: translateY(-60px);
	-moz-transform: translateY(-60px);
	-ms-transform: translateY(-60px);
	-o-transform: translateY(-60px);
	transform: translateY(-60px)
}
section#categories .category:hover .category-icon i {
	font-size: 32px
}
section#categories .category:hover .category-icon i:before {
	position: absolute;
	width: 100%;
	text-align: center;
	left: 0;
	line-height: 60px
}
section#categories .category:hover .category-icon i:after {
	content: '';
	display: block;
	width: 60px;
	height: 60px;
	margin: 0 auto;
	background: #fff;
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%
}
section#categories .category:hover .category-info {
	top: -45%
}
section#categories .category:hover .category-info a {
	color: #00a651
}
section#categories .category:hover p {
	opacity: 0
}
section#categories .category:hover .category-descr {
	opacity: 1;
	visibility: visible;
	-webkit-transition: all .6s ease .2s;
	-moz-transition: all .6s ease .2s;
	-ms-transition: all .6s ease .2s;
	-o-transition: all .6s ease .2s;
	transition: all .6s ease .2s;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1)
}
section#categories2 .cat-wrapper ul {
	background: #f0f0f0;
	border: 1px solid #e3e3e3;
	padding: 0
}
section#categories2 .cat-wrapper ul li {
	position: relative;
	padding: 10px 0 10px 30px;
	border-bottom: 1px solid #e3e3e3;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease
}
.job-post-wrapper .single-job-post, section#categories3 .category {
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease
}
section#categories2 .cat-wrapper ul li:last-child {
	border-bottom: 0
}
section#categories2 .cat-wrapper ul li:hover {
	background: #e3e3e3
}
section#categories2 .cat-wrapper ul li:before {
	content: '\f105';
	font-family: fontawesome;
	font-size: 18px;
	display: block;
	position: absolute;
	top: 50%;
	right: 30px;
	transform: translateY(-50%)
}
.coming-main .countdown li p, .coming-main .countdown li span, .job-post-wrapper .single-job-post .job-title a, section#categories3 .category .category-info a, section#countup .counter, section#latest-news .blog-post .post-info a, section.company-jobs .single-job-post .job-title a {
	font-family: 'Varela Round', sans-serif
}
section#categories2 .cat-wrapper ul li span {
	color: #999
}
section#categories3 {
	background: #f0f0f0
}
section#categories3 .category {
	position: relative;
	text-align: center;
	height: 250px;
	transition: all .6s ease
}
.testimonial .owl-item.active.center, section#signup-video .video-sec a {
	-moz-transition: all .8s ease;
	-ms-transition: all .8s ease;
	-o-transition: all .8s ease
}
section#categories3 .category:hover {
	background: #fff
}
section#categories3 .category .category-icon {
	width: 90px;
	height: 90px;
	margin: 0 auto
}
section#categories3 .category .category-icon img {
	height: 100%
}
section#categories3 .category .category-info a {
	font-size: 30px;
	color: #2e2e2e;
	text-transform: capitalize
}
section#categories3 .category .category-info a:hover {
	color: #00a651
}
section#signup-video {
	overflow: hidden
}
section#signup-video .signup-sec {
	background: #00a651
}
section#signup-video .signup-sec h2:after {
	background: #fff
}
section#signup-video .signup-sec img.signup-arrow {
	position: absolute;
	width: 65px;
	right: 15%;
	bottom: 10px
}
section#signup-video .signup-sec img.signup-icon {
	position: absolute;
	width: 300px;
	left: -10%;
	bottom: -20%
}
section#signup-video .video-sec {
	/*background: url(../images/img/image2.jpg) center center no-repeat;*/
	background-size: cover
}
section#signup-video .video-sec a {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all .8s ease;
	transition: all .8s ease;
	width: 100px;
	height: 100px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	display: block;
	background: 0 0;
	border: 7px solid #fff;
	margin: 0 auto;
	text-align: center;
	z-index: 5;
	opacity: .6
}
section#signup-video .video-sec a:hover {
	-webkit-transform: translateY(-50%) scale(.85);
	-moz-transform: translateY(-50%) scale(.85);
	-ms-transform: translateY(-50%) scale(.85);
	-o-transform: translateY(-50%) scale(.85);
	transform: translateY(-50%) scale(.85);
	background: rgba(255,255,255,.2);
	opacity: .8
}
section#signup-video .video-sec a:hover:after, section#signup-video .video-sec a:hover:before {
	content: '';
	position: absolute;
	height: 100%;
	opacity: 0;
	width: 100%;
	display: block
}
section#signup-video .video-sec a:hover:before {
	border: 2px solid #fff;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	animation: playButton .8s linear
}
section#signup-video .video-sec a:hover:after {
	top: 0;
	border: 1px solid #fff;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	animation: playButton .8s linear .4s
}
@keyframes playButton {
50% {
opacity:.6
}
100% {
-webkit-transform:scale(2.5);
-moz-transform:scale(2.5);
-o-transform:scale(2.5);
-ms-transform:scale(2.5);
transform:scale(2.5);
opacity:0
}
}
section#signup-video .video-sec a i {
	font-size: 42px;
	width: 100%;
	text-align: center;
	line-height: 85px;
	color: #fff
}
.job-post-main h2 i, .job-post-sidebar h2 i {
	padding-right: 10px
}
.job-post-wrapper .single-job-post {
	background: #fff;
	border-top: 1px solid #e3e3e3;
	border-left: 1px solid #e3e3e3;
	border-right: 1px solid #e3e3e3;
	padding: 30px;
	transition: all .6s ease
}
.job-post-wrapper .single-job-post:first-child {
	-webkit-border-radius: 5px 5px 0 0;
	-moz-border-radius: 5px 5px 0 0;
	border-radius: 5px 5px 0 0
}
.job-post-wrapper .single-job-post:last-child {
	border-bottom: 1px solid #e3e3e3;
	-webkit-border-radius: 0 0 5px 5px;
	-moz-border-radius: 0 0 5px 5px;
	border-radius: 0 0 5px 5px
}
.job-post-wrapper .single-job-post:hover {
	background: #f6f6f6
}
.job-post-wrapper .single-job-post .job-company {
	width: 85px;
	height: 85px;
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	background: #f0f0f0;
	/*padding: 20px;*/
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease
}
.testimonial .owl-item, .topic.expanded {
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease
}
.job-post-wrapper .single-job-post .job-company:hover {
	/*padding: 15px*/
}
.job-post-wrapper .single-job-post .job-info, .job-post-wrapper .single-job-post .job-title {
	padding-left: 20px
}
.job-post-wrapper .single-job-post .job-title a {
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 600;
	color: #333
}
.job-post-wrapper .single-job-post .job-title a:hover {
	color: #00a651
}
.job-post-wrapper .single-job-post .job-info {
	color: #666;
	text-transform: capitalize
}
.job-post-wrapper .single-job-post .job-info i {
	padding-right: 5px
}
.job-post-wrapper .single-job-post .job-info span.location {
	margin-left: 5px
}
.job-post-wrapper .single-job-post .job-category {
	float: right
}
ul.pagination {
	display: block;
	padding: 0;
	margin: 50px 0 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0
}
ul.pagination li a {
	font-size: 16px;
	color: #f6f6f6;
	background: #e76e34;
	border: 2px solid #e76e34;
	padding: 7px 14px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	float: none
}
ul.pagination li a:focus, ul.pagination li a:hover {
	background: 0 0;
	color: #e76e34;
	border-color: #e76e34
}
ul.pagination li.active a {
	background: 0 0;
	border: 2px solid #e76e34;
	color: #e76e34
}
ul.pagination li.active a:focus, ul.pagination li.active a:hover {
	background: 0 0;
	color: #e76e34;
	border-color: #e76e34
}
.widget {
	border: 1px solid #e3e3e3
}
.featured-job.widget {
	-webkit-border-radius: 5px 5px 0 0;
	-moz-border-radius: 5px 5px 0 0;
	border-radius: 5px 5px 0 0
}
.featured-job .company {
	background: #f0f0f0;
	padding: 70px 0;
	border-bottom: 1px solid #e3e3e3
}
.featured-job .company img {
	width: 85%;
	margin: 0 auto;
	display: block
}
.featured-job-info {
	padding: 30px 20px
}
.featured-job-info .job-title {
	overflow: hidden
}
.featured-job-info .job-title h5 {
	line-height: 27px
}
.featured-job-info .job-info {
	color: #666;
	text-transform: capitalize
}
.featured-job-info .job-info i {
	padding-right: 5px
}
.featured-job-info .job-info span#location {
	margin-left: 5px
}
.upload-resume {
	padding: 30px 20px;
	background: #f0f0f0
}
.upload-resume p {
	color: #999
}
section#countup {
	background: #f0f0f0;
	text-align: center
}
section#countup .counter {
	font-size: 50px;
	color: #00a651;
}
section#countup h4 {
	text-transform: uppercase
}
section#testimonials {
	/*background: url(../images/img/testimonial-bg.jpg) no-repeat;*/
	background-size: cover
}
section#testimonials .section-title h2:after {
	background: #fff
}
.testimonial .owl-stage-outer {
	padding-top: 25px
}
.testimonial .owl-item {
	-webkit-transform: scale(.7);
	-moz-transform: scale(.7);
	-ms-transform: scale(.7);
	-o-transform: scale(.7);
	transform: scale(.7);
	opacity: .5;
	transition: all .3s ease
}
.testimonial .owl-item.active.center {
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none;
	opacity: 1;
	-webkit-transition: all .8s ease;
	transition: all .8s ease
}
.testimonial .owl-item .item {
	text-align: center;
	color: #f6f6f6
}
.testimonial .owl-item .item .review {
	position: relative;
	background: #fff;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
.testimonial .owl-item .item .review:before {
	content: '\f10e';
	font-family: fontawesome;
	font-size: 24px;
	position: absolute;
	width: 50px;
	height: 50px;
	top: -25px;
	left: 25px;
	background: #00a651;
	border: 2px solid #fff;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	line-height: 50px
}
section#latest-news .blog-post .blog-post-thumbnail, section#latest-news2 .blog-post {
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	overflow: hidden
}
.testimonial .owl-item .item .review:after {
	content: '';
	position: absolute;
	bottom: -10px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 10px 10px 0;
	border-color: #fff transparent transparent
}
.testimonial .owl-item .item .review blockquote {
	border: 0;
	font-style: italic;
	color: #666;
	padding: 40px
}
.testimonial .owl-item .item .customer {
	margin-top: 40px
}
.testimonial .owl-item .item .customer img {
	width: 100px;
	height: auto;
	border: 3px solid #fff;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	margin: 0 auto
}
section#latest-news .blog-post {
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	overflow: hidden
}
section#latest-news .blog-post .blog-post-thumbnail {
	height: 240px;
	width: 100%;
	position: relative;
	background: linear-gradient(to right, #00a651 -20%, #443088 120%)
}
section#latest-news .blog-post .blog-post-thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	-webkit-transition: all .8s ease;
	-moz-transition: all .8s ease;
	-ms-transition: all .8s ease;
	-o-transition: all .8s ease;
	transition: all .8s ease
}
section#latest-news .blog-post .blog-post-thumbnail:hover img {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
	-webkit-filter: blur(3px);
	-moz-filter: blur(3px);
	-ms-filter: blur(3px);
	-o-filter: blur(3px);
	filter: blur(3px);
	opacity: .4
}
section#latest-news .blog-post .post-info {
	position: relative;
	background: #f0f0f0;
	padding: 20px
}
section#latest-news .blog-post .post-info:before {
	content: '';
	position: absolute;
	top: -25px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 174px 20px;
	border-color: transparent transparent #f0f0f0;
	z-index: 5;
	-webkit-transition: all .4s linear;
	-moz-transition: all .4s linear;
	-ms-transition: all .4s linear;
	-o-transition: all .4s linear;
	transition: all .4s linear
}
section#latest-news .blog-post:hover .post-info:before {
	content: '';
	position: absolute;
	top: 0;
	border-width: 0 174px
}
section#latest-news .blog-post .post-info:after {
	content: '';
	position: absolute;
	top: -5px;
	left: 0;
	width: 100%;
	height: 5px;
	background: #f0f0f0;
	z-index: 4
}
section#latest-news .blog-post .post-info a {
	color: #333;
	font-size: 18px;
	font-weight: 600
}
section#latest-news .blog-post .post-info a:hover {
	color: #e76e34
}
section#latest-news .blog-post .post-info .post-details {
	color: #999
}
section#latest-news .blog-post .post-info .post-details .date:after {
	content: '|';
	padding: 0 10px
}
section#latest-news .blog-post .post-info .post-details span i {
	padding-right: 5px
}
section#latest-news .blog-post .post-info p {
	margin-top: 15px;
	margin-bottom: 0;
	color: #666;
	font-size: 14px;
	line-height: 22px
}
section#latest-news .blog-post>a.btn {
	position: absolute;
	bottom: -13px;
	right: 30px
}
section#latest-news .blog-post-ver2 .post-info {
	padding: 40px 20px;
	text-align: center
}
section#latest-news2 .blog-post {
	height: 300px;
	background: linear-gradient(to right, #00a651 -20%, #443088 120%);
	position: relative;
	padding: 0
}
section#latest-news2 .blog-post .post-info {
	width: 100%;
	position: absolute;
	bottom: -20px;
	z-index: 1;
	padding: 0 40px;
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	-ms-transition: all .4s ease;
	-o-transition: all .4s ease;
	transition: all .4s ease;
	-webkit-transform: scale(.9);
	-moz-transform: scale(.9);
	-ms-transform: scale(.9);
	-o-transform: scale(.9);
	transform: scale(.9);
	opacity: .9
}
section#latest-news2 .blog-post:hover .post-info {
	bottom: 20px;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	opacity: 1
}
section#latest-news2 .blog-post-featured:hover img, section#latest-news2 .blog-post:hover img {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	-webkit-filter: blur(4px)
}
section#latest-news2 .blog-post .post-info a.blog-post-link {
	color: #f6f6f6;
	display: block
}
section#latest-news2 .blog-post .post-info span {
	font-size: 14px;
	color: #f6f6f6;
	opacity: .9
}
section#latest-news2 .blog-post img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: .6;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-ms-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease
}
section#latest-news2 .blog-post:hover img {
	transform: scale(1.2);
	-moz-filter: blur(4px);
	-ms-filter: blur(4px);
	-o-filter: blur(4px);
	filter: blur(4px);
	opacity: .4
}
section#latest-news2 .blog-post-featured {
	height: 640px;
	max-height: 640px;
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: linear-gradient(to right, #00a651 -20%, #443088 120%);
	position: relative;
	padding: 0;
	overflow: hidden
}
section#latest-news2 .blog-post-featured span.featured {
	position: absolute;
	z-index: 1;
	box-shadow: none;
	top: 30px;
	right: 50px;
	color: #f6f6f6;
	background: #e76e34
}
section#latest-news2 .blog-post-featured .post-info {
	width: 100%;
	position: absolute;
	bottom: -20px;
	z-index: 1;
	padding: 0 40px;
	-webkit-transition: all .4s ease;
	-moz-transition: all .4s ease;
	-ms-transition: all .4s ease;
	-o-transition: all .4s ease;
	transition: all .4s ease;
	-webkit-transform: scale(.9);
	-moz-transform: scale(.9);
	-ms-transform: scale(.9);
	-o-transform: scale(.9);
	transform: scale(.9);
	opacity: .9
}
section#latest-news2 .blog-post-featured:hover .post-info {
	bottom: 20px;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	opacity: 1
}
section#latest-news2 .blog-post-featured .post-info a.blog-post-link {
	color: #f6f6f6;
	display: block
}
section#latest-news2 .blog-post-featured .post-info span {
	font-size: 14px;
	color: #f6f6f6;
	opacity: .9
}
section#latest-news2 .blog-post-featured img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: .6;
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease
}
section#latest-news2 .blog-post-featured:hover img {
	transform: scale(1.2);
	-moz-filter: blur(4px);
	-ms-filter: blur(4px);
	-o-filter: blur(4px);
	filter: blur(4px);
	opacity: .4
}
section#partners {
	background-color: #f0f0f0
}
#partners .owl-item {
	min-height: 30px
}
#partners img {
	width: 130px;
	opacity: .5;
	-webkit-transition: all .9s ease;
	-moz-transition: all .9s ease;
	-ms-transition: all .9s ease;
	-o-transition: all .9s ease;
	transition: all .9s ease;
	margin: 0 auto;
	display: block
}
#partners img:hover {
	opacity: 1
}
section.get-started {
	background-blend-mode: overlay;
	/*background: url(../images/img/get-started-bg.png) no-repeat, -moz-linear-gradient(to right, #00a651 -20%, #443088 120%);
	background: url(../images/img/get-started-bg.png) no-repeat, -webkit-linear-gradient(to right, #00a651 -20%, #443088 120%);
	background: url(../images/img/get-started-bg.png) no-repeat, linear-gradient(to right, #00a651 -20%, #443088 120%);*/
	background-position: center center;
	background-size: cover
}
section.cariera-app {
	background-blend-mode: overlay;
	/*background: url(../images/img/app-bg.png) no-repeat #e76e34;*/
	background-size: cover
}
section#version3.search-jobs, section.job-search {
	background: #f0f0f0
}
section.cariera-app ul li i {
	padding-right: 10px
}
section.cariera-app .app-wrapper {
	height: 300px
}
section.cariera-app .app-wrapper img {
	width: 60%;
	display: block;
	margin: 0 auto;
	position: relative;
	bottom: -10px
}
section.search-jobs .job-search-form input {
	font-size: 14px;
	border: 1px solid #e3e3e3;
	height: auto
}
section.search-jobs .job-search-form .search-categories .btn-group.bootstrap-select button {
	font-size: 14px;
	height: 42px;
	border: 1px solid #e3e3e3
}
section.search-jobs .job-search-form .search-categories .btn-group.bootstrap-select button:hover {
	font-size: 14px
}
section.search-jobs .job-search-form .search-location:before {
	font-size: 18px;
	line-height: 42px
}
section.search-jobs .job-search-form .search-submit button {
	height: 42px;
	line-height: 42px
}
section#version2.search-jobs .job-post-sidebar .search-location:before {
	content: '\f276';
	font-family: fontawesome;
	font-size: 18px;
	position: absolute;
	right: 30px;
	color: #999;
	line-height: 42px
}
section#version2.search-jobs .job-post-sidebar .job-types ul {
	padding: 0 5px
}
section#version2.search-jobs .job-post-sidebar .job-types ul li {
	padding: 5px 0;
	width: 49%
}
section#version2.search-jobs .job-post-sidebar .job-types ul li label {
	font-weight: 400;
	padding-left: 30px
}
section#version2.search-jobs .job-post-sidebar .job-categories .btn-group.bootstrap-select button {
	font-size: 14px;
	height: 42px;
	border: 1px solid #e3e3e3
}
section#version2.search-jobs .job-post-sidebar .job-categories .btn-group.bootstrap-select button:hover {
	font-size: 14px
}
section#version3.search-jobs .job-post-wrapper .single-job-post {
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
section#version3.search-jobs .job-post-wrapper .single-job-post:hover {
	background: #fff
}
section#version4.search-jobs {
	background: #f0f0f0
}
section#version4.search-jobs .item-block {
	background: #fff;
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
section#version4.search-jobs .item-block .job-post-header {
	padding: 20px;
	border-bottom: 1px solid #e3e3e3
}
section#version4.search-jobs .item-block .job-post-header a {
	color: #323232
}
section#version4.search-jobs .item-block .job-post-header a:hover {
	color: #e76e34
}
section#version4.search-jobs .item-block .job-post-header img {
	width: 64px;
	margin-right: 30px;
	float: left
}
.gmaps #map, section.company-jobs .single-job-post .job-category, section.find-candidate .candidate-wrapper .single-candidate .candidate-cta {
	float: right
}
section#version4.search-jobs .item-block .job-post-header>div {
	display: inline-block;
	padding-top: 10px
}
section#version4.search-jobs .item-block .job-post-header>ul {
	padding-top: 5px;
	text-align: center
}
section#version4.search-jobs .item-block .job-post-header>ul li {
	padding: 5px 0
}
section#version4.search-jobs .item-block .job-post-header .time {
	color: #999;
	font-size: 12px;
	letter-spacing: 0
}
section#version4.search-jobs .item-block .job-post-body {
	padding: 20px
}
section#version4.search-jobs .item-block .job-post-footer {
	margin: 0;
	padding: 10px;
	border-top: 1px solid #e3e3e3;
	color: #999;
	text-align: center
}
section#version4.search-jobs .item-block .job-post-footer i {
	padding-right: 10px
}
.account-question {
	margin-left: 0;
	margin-right: 0;
	margin-top: 20px;
	background: #f0f0f0;
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 20px
}
form.post-job-resume .form-group {
	margin-bottom: 25px
}
form.post-job-resume label {
	display: block;
	text-transform: capitalize;
	font-size: 16px;
	margin-bottom: 10px
}
form.post-job-resume label span {
	text-transform: none;
	font-style: italic;
	color: #999;
	padding-left: 5px
}
form.post-job-resume span.form-msg {
	margin-top: 10px;
	display: block;
	color: #999
}
form.post-job-resume h3 {
	border-bottom: 1px solid #e3e3e3
}
form.post-job-resume #last.form-group {
	border-top: 1px solid #e3e3e3
}
form.post-job-resume .form-group .bootstrap-select {
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 3px 20px
}
form.post-job-resume .form-group .bootstrap-select button.dropdown-toggle, form.post-job-resume .form-group .bootstrap-select button.dropdown-toggle:hover {
	font-size: 14px;
	padding: 0;
	box-shadow: none;
	background: 0 0;
	height: auto
}
form.post-job-resume .mce-tinymce {
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
form.post-job-resume .mce-panel {
	border-color: #e3e3e3
}
form.post-job-resume .upload-file-btn {
	position: relative;
	overflow: hidden;
	background: #e76e34;
	color: #f6f6f6;
	padding: 10px 20px;
	font-size: 14px;
	font-weight: 700;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	text-align: center;
	display: inline-block
}
#map .gmnoprint, section.find-candidate form label {
	display: none
}
form.post-job-resume .upload-file-btn input[type=file] {
	position: absolute;
	width: 100%;
	height: 165%;
	top: -25px;
	left: 0;
	opacity: 0;
	cursor: pointer
}
section#find-candidate.page-header {
	/*background: url(../images/img/candidate-bg.jpg) no-repeat;*/
	background-size: cover
}
section.find-candidate form button {
	height: 42px;
	line-height: 42px
}
section.find-candidate form button i {
	padding-right: 10px
}
section.find-candidate .candidate-wrapper .single-candidate {
	background: #fff;
	border-top: 1px solid #e3e3e3;
	border-left: 1px solid #e3e3e3;
	border-right: 1px solid #e3e3e3;
	padding: 30px;
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease
}
section.find-candidate .candidate-wrapper .single-candidate:first-child {
	-webkit-border-radius: 5px 5px 0 0;
	-moz-border-radius: 5px 5px 0 0;
	border-radius: 5px 5px 0 0
}
section.find-candidate .candidate-wrapper .single-candidate:last-child {
	border-bottom: 1px solid #e3e3e3;
	-webkit-border-radius: 0 0 5px 5px;
	-moz-border-radius: 0 0 5px 5px;
	border-radius: 0 0 5px 5px
}
section.find-candidate .candidate-wrapper .single-candidate:hover {
	background: #f6f6f6
}
section.find-candidate .candidate-wrapper .single-candidate .candidate-img {
	width: 85px;
	height: 85px;
	border: 1px solid #e3e3e3;
	overflow: hidden;
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: linear-gradient(to right, #00a651 -20%, #443088 120%)
}
section.find-candidate .candidate-wrapper .single-candidate .candidate-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease
}
section.find-candidate .candidate-wrapper .single-candidate .candidate-img:hover img {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
	-webkit-filter: blur(2px);
	-moz-filter: blur(2px);
	-ms-filter: blur(2px);
	-o-filter: blur(2px);
	filter: blur(2px);
	opacity: .4
}
section.find-candidate .candidate-wrapper .single-candidate .candidate-name a {
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 600;
	color: #333
}
section.find-candidate .candidate-wrapper .single-candidate .candidate-name a:hover {
	color: #00a651
}
section.find-candidate .candidate-wrapper .single-candidate .candidate-info {
	color: #666
}
section.find-candidate .candidate-wrapper .single-candidate .candidate-info i {
	padding-right: 10px
}
section#version2.find-candidate {
	background: #f0f0f0
}
section#version2.find-candidate .candidate-wrapper .single-candidate {
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
section#version2.find-candidate .candidate-wrapper .single-candidate:hover {
	background: #fff
}
section.about-process {
	background: #f0f0f0
}
section.about-process .process-icon {
	background: #00a651;
	width: 120px;
	height: 120px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	margin: 0 auto;
	text-align: center
}
section.about-process .process-icon i {
	font-size: 50px;
	color: #fff;
	line-height: 120px
}
section#contact {
	background: #f0f0f0;
	position: relative
}
section#contact p {
	color: #999
}
form#contact-form textarea {
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 10px 20px;
	box-shadow: none;
	max-width: 100%;
	resize: none
}
.gmaps, .gmaps #map {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px
}
form#contact-form textarea:focus {
	box-shadow: none
}
form#contact-form .form-control::-webkit-input-placeholder {
color:#999
}
form#contact-form .form-control:-moz-placeholder {
color:#999
}
form#contact-form .form-control::-moz-placeholder {
color:#999
}
form#contact-form .form-control:-ms-input-placeholder {
color:#999
}
form#contact-form div#contact-result {
	position: fixed;
	left: 0;
	bottom: -50px;
	width: 100%;
	color: #f6f6f6;
	text-align: center;
	padding: 15px 0;
	margin: 0;
	z-index: 15;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease;
	-webkit-transform: translateZ(0)
}
form#contact-form div#contact-result.error, form#contact-form div#contact-result.valid {
	bottom: 0;
	opacity: 1;
	visibility: visible
}
form#contact-form div#contact-result.valid {
	background: #2ecc71
}
form#contact-form div#contact-result.error {
	background: #dc464a
}
.gmaps {
	height: 600px;
	padding: 0;
	border-radius: 5px
}
.gmaps #map {
	width: 90%;
	height: 100%;
	border-radius: 5px;
	box-shadow: 0 5px 20px 0 rgba(0,0,0,.1)
}
.gmaps .gm-style {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
.gmaps2 {
	height: 400px;
	padding: 0
}
.gmaps2 #map {
	width: 100%;
	height: 100%
}
section#companies .company-letters {
	border-bottom: 2px solid #e3e3e3;
	background: #f6f6f6;
	text-align: center;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
section#companies .company-letters a {
	font-weight: 700;
	display: inline-block;
	padding: 15px 10px;
	color: #333
}
section#companies .company-letters a:hover {
	color: #00a651
}
section#companies .company-group {
	margin-bottom: 40px
}
section#companies .company-group .company-letter {
	font-size: 24px;
	color: #666;
	padding: 0 0 10px 10px;
	border-bottom: 2px solid #e3e3e3
}
section.education .item-block .education-header, section.work-experience .item-block .experience-header {
	border-bottom: 1px solid #e3e3e3;
	padding: 20px
}
section#companies .company-group li {
	padding: 10px
}
section#companies .company-group li:hover {
	background: #f0f0f0
}
section#companies .company-group li a {
	color: #666;
	font-size: 18px
}
section#companies .company-group li a:hover {
	color: #00a651
}
section#company-profile .company-profile {
	position: relative;
	margin-top: -150px;
	z-index: 10;
	background: #fff;
	padding: 40px 20px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
/* By SJ */
.fa-thumbs-up{color:#00a651;
}
.fa-thumbs-down{color:#F00;
}
/* BY SJ */
section#company-profile .company-profile .profile-photo {
	background: #f0f0f0;
	border: 1px solid #e3e3e3;
	padding: 60px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	overflow: hidden
}
section#company-profile .profile-info li {
	display: inline-block;
	width: 33%;
	padding: 5px 0;
	color: #666;
	font-size: 16px
}
section#company-profile .profile-info li a {
	color: #666
}
section#company-profile .profile-info li a:hover {
	color: #00a651
}
section#company-profile .profile-info li i {
	padding-right: 10px
}
section#company-profile2 .company-profile {
	position: relative;
	margin-top: -120px;
	z-index: 10
}
section#company-profile2 .company-profile .profile-photo {
	background: #f0f0f0;
	overflow: hidden;
	width: 240px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	margin: 0 auto
}
section#company-profile2 .company-profile .profile-photo:after, section#company-profile2 .company-profile .profile-photo:before {
	content: '';
	position: absolute;
	width: 240px;
	height: 240px;
	display: block;
	background: #00a651;
	z-index: -1
}
section#company-profile2 .company-profile .profile-photo img {
	-webkit-transform: scale(.5);
	-moz-transform: scale(.5);
	-ms-transform: scale(.5);
	-o-transform: scale(.5);
	transform: scale(.5)
}
section#company-profile2 .company-profile .profile-photo:before {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	transform: scale(1.1);
	opacity: .2
}
section#company-profile2 .company-profile .profile-photo:after {
	top: 0;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	transform: scale(1.2);
	opacity: .1
}
section#company-profile2 .profile-info {
	border: 1px solid #e3e3e3;
	padding: 40px;
	background: #f0f0f0
}
section#company-profile2 .profile-info li {
	padding: 10px 0;
	color: #666;
	font-size: 16px
}
section#company-profile2 .profile-info li a {
	color: #666
}
section#company-profile2 .profile-info li a:hover {
	color: #00a651
}
section#company-profile2 .profile-info li i {
	padding-right: 10px
}
section.company-jobs {
	background: #f0f0f0
}
section.company-jobs .single-job-post {
	background: #fff;
	border: 1px solid #e3e3e3;
	padding: 30px
}
section.company-jobs .single-job-post .job-company {
	width: 85px;
	height: 85px;
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	background: #f0f0f0;
	padding: 20px;
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease
}
section.company-jobs .single-job-post .job-company:hover {
	padding: 15px
}
section.company-jobs .single-job-post .job-title a {
	font-size: 22px;
	text-transform: uppercase;
	font-weight: 600;
	color: #333;
	line-height: 45px
}
section.company-jobs .single-job-post .job-title a:hover {
	color: #00a651
}
section.profile-header {
	position: relative;
	height: 300px;
	/*background: url(../images/img/image2.jpg) no-repeat fixed;
	background-size: cover;*/
	background:#00a651;
}
section.profile-header:after, section.profile-header:before {
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	overflow: hidden;
	left: 0;
	content: '';
	top: 0
}
section.profile-header:before {
	background: rgba(0,0,0,.6);
	z-index: 2
}
section.profile-header:after {
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: linear-gradient(to right, #00a651 -20%, #443088 120%);
	z-index: 3;
	opacity: .4
}
section#candidate-profile .candidate-profile {
	position: relative;
	margin-top: -150px;
	z-index: 10;
	background: #fff;
	padding: 40px 20px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
section#candidate-profile .candidate-profile .profile-photo {
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	overflow: hidden
}
section#candidate-profile .profile-info li {
	display: inline-block;
	width: 33%;
	padding: 5px 0;
	color: #666;
	font-size: 16px
}
section#candidate-profile .profile-info li a {
	color: #666
}
section#candidate-profile .profile-info li a:hover {
	color: #00a651
}
section#candidate-profile .profile-info li i {
	padding-right: 10px
}
.skillbar {
	position: relative;
	display: block;
	margin-bottom: 25px!important;
	width: 80%;
	margin: 0 auto;
	background: #e3e3e3;
	height: 30px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-webkit-transition: .4s linear;
	-moz-transition: .4s linear;
	-ms-transition: .4s linear;
	-o-transition: .4s linear;
	transition: .4s linear;
	-webkit-transition-property: width, background-color;
	-moz-transition-property: width, background-color;
	-ms-transition-property: width, background-color;
	-o-transition-property: width, background-color;
	transition-property: width, background-color
}
.skillbar:last-child {
	margin-bottom: 0!important
}
.skillbar-title {
	position: absolute;
	top: 0;
	left: 0;
	width: 120px;
	text-align: center;
	font-size: 14px;
	color: #fff;
	-webkit-border-radius: 5px 0 0 5px;
	-moz-border-radius: 5px 0 0 5px;
	border-radius: 5px 0 0 5px;
	background: #00a651
}
.skillbar-title span {
	display: block;
	background: rgba(0,0,0,.2);
	padding: 0 20px;
	line-height: 30px
}
.skillbar-bar {
	height: 30px;
	width: 0;
	background: -moz-linear-gradient(left, #00a651 20%, #443088 140%);
	background: -webkit-linear-gradient(left, #00a651 20%, #443088 140%);
	background: linear-gradient(to right, #00a651 20%, #443088 140%);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
.skill-bar-percent {
	position: absolute;
	right: 10px;
	top: 0;
	font-size: 12px;
	height: 30px;
	line-height: 30px;
	color: #666
}
section.portfolio {
	background: #f0f0f0
}
section#version1.blog-listing article.blog-post .blog-thumbnail, section.portfolio .portfolio-grid .element figure {
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	overflow: hidden
}
section.portfolio ul#portfolio-sorting li {
	padding: 0 10px
}
section.portfolio ul#portfolio-sorting li a {
	color: #323232;
	font-weight: 700;
	display: block;
	opacity: .8;
	line-height: 30px
}
section.portfolio ul#portfolio-sorting li a.current {
	color: #00a651;
	opacity: 1
}
section.portfolio .portfolio-grid .element {
	margin-bottom: 40px;
	overflow: hidden
}
section.portfolio .portfolio-grid .element figure {
	width: 100%;
	height: 100%;
	position: relative;
	background: linear-gradient(to right, #00a651 -20%, #443088 120%)
}
section.portfolio .portfolio-grid .element figure img {
	-webkit-transition: all .8s ease;
	-moz-transition: all .8s ease;
	-ms-transition: all .8s ease;
	-o-transition: all .8s ease;
	transition: all .8s ease
}
section.portfolio .portfolio-grid .element:hover figure img {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
	-webkit-filter: blur(3px);
	-moz-filter: blur(3px);
	-ms-filter: blur(3px);
	-o-filter: blur(3px);
	filter: blur(3px);
	opacity: .4
}
section.education .item-block {
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
section.education .item-block .education-header img {
	width: 64px;
	margin-right: 30px;
	float: left
}
section.education .item-block .education-header>div {
	display: inline-block
}
section.education .item-block .education-header .time {
	display: inline-block;
	float: right;
	color: #999;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 64px;
	text-align: right
}
section.education .item-block .education-body {
	padding: 20px
}
section.work-experience {
	background: #f0f0f0
}
section.work-experience .item-block {
	background: #fff;
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
section.work-experience .item-block .experience-header img {
	width: 64px;
	margin-right: 30px;
	float: left
}
section.work-experience .item-block .experience-header>div {
	display: inline-block;
	padding-top: 10px
}
section.work-experience .item-block .experience-header .time {
	display: inline-block;
	float: right;
	color: #999;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 64px;
	text-align: right
}
section.work-experience .item-block .experience-body {
	padding: 20px
}
section#candidate-profile2 .candidate-profile {
	position: relative;
	margin-top: -120px;
	z-index: 10
}
section#candidate-profile2 .candidate-profile .profile-photo {
	overflow: hidden;
	width: 240px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	margin: 0 auto
}
section#candidate-profile2 .candidate-profile .profile-photo:before {
	content: '';
	position: absolute;
	width: 240px;
	height: 240px;
	display: block;
	background: #00a651;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	z-index: -1;
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	transform: scale(1.1);
	opacity: .2
}
section#candidate-profile2 .candidate-profile .profile-photo:after {
	content: '';
	position: absolute;
	top: 0;
	width: 240px;
	height: 240px;
	display: block;
	background: #00a651;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	z-index: -1;
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
	opacity: .1
}
section#candidate-profile2 .profile-info {
	border: 1px solid #e3e3e3;
	padding: 40px;
	background: #f0f0f0
}
section#candidate-profile2 .profile-info li {
	padding: 10px 0;
	color: #666;
	font-size: 16px
}
section#candidate-profile2 .profile-info li a {
	color: #666
}
section#candidate-profile2 .profile-info li a:hover {
	color: #00a651
}
section#candidate-profile2 .profile-info li i {
	padding-right: 10px
}
.chart {
	position: relative;
	display: inline-block;
	font-size: 14px;
	font-weight: 700;
	color: #666;
	text-align: center
}
.chart span {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}
.live-search-box {
	padding: 15px
}
.live-search-box:focus {
	border: 1px solid #e76e34
}
.topic {
	padding: 0 20px;
	border-bottom: solid 1px #ebebeb
}
.topic.expanded {
	background-color: #f4f4f4;
	transition: all .3s ease
}
.topic .open {
	cursor: pointer;
	display: block;
	padding: 0
}
.topic .open:hover .question {
	color: #e76e34
}
.topic .question {
	padding: 20px 0;
	color: #323232;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease
}
.topic .answer {
	font-size: 16px;
	display: none;
	margin-bottom: 30px;
	padding-left: 20px;
	padding-right: 20px
}
.topic i {
	font-size: 18px;
	color: #323232;
	position: absolute;
	top: 20px;
	right: 20px;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-ms-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease
}
.topic.expanded i {
	-webkit-transform: rotate(-180deg);
	-moz-transform: rotate(-180deg);
	-ms-transform: rotate(-18deg);
	-o-transform: rotate(-180deg);
	transform: rotate(-180deg)
}
section.job-header {
	background: #f0f0f0
}
section.job-header h3 {
	text-transform: uppercase;
	color: #666
}
section.job-header a i {
	padding-right: 10px
}
section#job-page .company-info {
	padding-bottom: 30px;
	border-bottom: 1px solid #e3e3e3
}
section#job-page .company-info .job-company {
	width: 120px;
	height: 120px;
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	background: #f0f0f0;
	padding: 30px;
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease
}
section#job-page .company-info .job-company:hover {
	padding: 20px
}
section#job-page .company-info .job-company-info ul li a {
	font-size: 16px
}
section#job-page .company-info .job-company-info ul li a i {
	padding-right: 10px
}
section#job-page .job-sidebar {
	border: 1px solid #e3e3e3;
	padding: 35px
}
section#job-page .job-overview li {
	padding: 10px 0
}
section#job-page .job-overview li:first-child {
	padding-top: 0
}
section#job-page .job-overview li h5 {
	padding-bottom: 10px
}
section#job-page .job-overview li h5 i {
	font-size: 18px;
	padding-right: 5px;
	color: #e76e34
}
section#job-page .gmaps {
	height: 350px
}
section#job-page .gmaps #map {
	width: 100%;
	height: 100%;
	float: none;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	box-shadow: none;
	border: 1px solid #e3e3e3
}
section#job-page .related-jobs .item {
	background: #f0f0f0;
	padding: 40px 20px;
	text-align: center;
	border-top: 3px solid transparent;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease
}
section#job-page .related-jobs .item:hover {
	border-top: 3px solid #e76e34
}
section#job-page .related-jobs .owl-nav.disabled+.owl-dots {
	text-align: center;
	margin-top: 30px
}
section#job-page .related-jobs .owl-dots .owl-dot {
	display: inline-block;
	zoom: 1
}
section#job-page .related-jobs .owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	margin: 5px 7px;
	background: #ddd;
	display: block;
	-webkit-backface-visibility: visible;
	-webkit-transition: all .2s ease;
	-moz-transition: all .2s ease;
	-ms-transition: all .2s ease;
	-o-transition: all .2s ease;
	transition: all .2s ease;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%
}
section#job-page .related-jobs .owl-dots .owl-dot.active span {
	background: #00a651
}
section#page-not-found {
	/*background: url(../images/img/404-bg.png) left center no-repeat;*/
	text-align: center
}
section#page-not-found h2, section#page-not-found2 h2 {
	font-size: 120px
}
section#page-not-found2 {
	text-align: center
}
.coming-soon {
	width: 100%;
	min-height: 100%;
	background-blend-mode: overlay;
	/*background: url(../images/img/coming-soon-bg.png) no-repeat, -moz-linear-gradient(to right, #00a651 -20%, #443088 120%);
	background: url(../images/img/coming-soon-bg.png) no-repeat, -webkit-linear-gradient(to right, #00a651 -20%, #443088 120%);
	background: url(../images/img/coming-soon-bg.png) no-repeat, linear-gradient(to right, #00a651 -20%, #443088 120%);*/
	background-position: left center;
	z-index: 5
}
.coming-main {
	position: relative;
	z-index: 7;
	padding-top: 100px
}
.coming-main h2 {
	font-size: 60px;
	text-transform: uppercase
}
.coming-main .logo img {
	width: 200px;
	margin: 0 auto;
	display: block;
	padding-top: 20px
}
.coming-main .countdown {
	text-align: center
}
.coming-main .countdown ul {
	list-style: none;
	margin: 0
}
.coming-main .countdown li span {
	color: #f6f6f6;
	font-size: 90px
}
.coming-main .countdown li p {
	color: #f6f6f6;
	font-size: 26px;
	font-weight: 300;
	text-transform: capitalize
}
.coming-main .mailchimp .form-group {
	width: 100%;
	text-align: center
}
.coming-main .mailchimp .form-group input {
	width: 50%
}
.coming-main .mailchimp .form-group button {
	height: 42px;
	line-height: 42px;
	margin-left: 10px
}
.coming-main .cta a:last-child {
	margin-left: 10px
}
section#login {
	background: #f0f0f0
}
section#login .login-box {
	position: relative;
	background: #fff;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px
}
section#login .login-box .login-title {
	padding: 20px 30px;
	text-align: center;
	text-transform: uppercase;
	background: #e76e34;
	color: #f6f6f6;
	-webkit-border-radius: 10px 10px 0 0;
	-moz-border-radius: 10px 10px 0 0;
	border-radius: 10px 10px 0 0
}
section#login .login-box .login-title:before {
	content: "";
	width: 100%;
	height: 30px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	position: absolute;
	top: -10px;
	background: #e76e34;
	left: 0;
	-webkit-transform: scale(.95);
	-moz-transform: scale(.95);
	-ms-transform: scale(.95);
	-o-transform: scale(.95);
	transform: scale(.95);
	opacity: .4
}
section#login .login-box form {
	padding: 40px
}
section#login .login-box form .form-group:last-child {
	margin-bottom: 0
}
section#login .login-box form a {
	color: #333;
	font-weight: 700
}
section#login .login-box form a:hover {
	color: #e76e34
}
section#register {
	background: #f0f0f0
}
section#register .nav-tabs>li {
	text-align: center
}
section#register .nav-tabs>li.active>a {
	color: #333;
	border-top: 3px solid #00a651
}
section#register .nav-tabs>li.active>a:hover {
	background: #fff;
	border-top: 3px solid #00a651;
	border-right: 1px solid #e3e3e3;
	border-bottom: 1px solid #fff;
	border-left: 1px solid #e3e3e3
}
section#register .nav-tabs>li>a {
	padding: 15px 30px;
	color: #666;
	border-top: 3px solid transparent
}
section#register .nav-tabs>li>a:hover {
	background: 0 0;
	border: 1px solid transparent;
	border-top: 3px solid transparent
}
section#register .nav-tabs>li>a:focus {
	color: #333!important
}
section#register .tab-content {
	background: #fff;
	border: 1px solid #e3e3e3;
	border-top: 0;
	-webkit-border-radius: 0 0 10px 10px;
	-moz-border-radius: 0 0 10px 10px;
	border-radius: 0 0 10px 10px
}
section#register .tab-content .tab-pane {
	padding: 0 40px
}
section.blog-listing article.blog-post .blog-thumbnail:hover img {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
	-webkit-filter: blur(3px);
	-moz-filter: blur(3px);
	-ms-filter: blur(3px);
	-o-filter: blur(3px);
	filter: blur(3px);
	opacity: .4
}
section.blog-listing article.blog-post .date {
	background: #00a651;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	color: #f6f6f6;
	text-align: center;
	padding: 10px 15px;
	line-height: 1;
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease
}
section.blog-listing article.blog-post .blog-thumbnail:hover .date {
	opacity: 0
}
section.blog-listing article.blog-post .date span.day {
	display: block;
	font-size: 24px;
	font-weight: 700
}
section.blog-listing article.blog-post .date span.publish-month {
	font-size: 14px;
	text-transform: uppercase
}
section.blog-listing article.blog-post .blog-desc h5 a {
	font-family: inherit;
	color: #333;
	text-transform: uppercase;
	font-weight: 700
}
section.blog-listing article.blog-post .blog-desc h5 a:hover {
	color: #00a651
}
section.blog-listing article.blog-post .blog-desc p {
	font-size: 14px
}
section.blog-listing article.blog-post .blog-desc .post-detail {
	color: #999
}
section.blog-listing article.blog-post .blog-desc .post-detail span:after {
	content: '|';
	padding: 0 7px
}
section.blog-listing article.blog-post .blog-desc .post-detail span:last-child:after {
	display: none
}
section.blog-listing article.blog-post .blog-desc .post-detail span i {
	padding-right: 5px
}
section.blog-listing .pagination {
	margin-top: 30px
}
section#version1.blog-listing article.blog-post {
	border-top: 0;
	border-right: 0;
	border-bottom: 1px solid #e3e3e3;
	border-left: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	padding-bottom: 40px;
	margin-bottom: 40px
}
section#version1.blog-listing article.blog-post:last-of-type {
	border-bottom: 0;
	margin-bottom: 0
}
section#version1.blog-listing article.blog-post .blog-thumbnail {
	background: linear-gradient(to right, #00a651 -20%, #443088 120%);
	padding: 0
}
section#version1.blog-listing article.blog-post .blog-thumbnail img {
	max-height: 300px;
	object-fit: cover;
	height: 260px;
	width: 100%;
	-webkit-transition: all .8s ease;
	-moz-transition: all .8s ease;
	-ms-transition: all .8s ease;
	-o-transition: all .8s ease;
	transition: all .8s ease
}
section#version1.blog-listing article.blog-post .blog-desc {
	padding-left: 30px
}
section#version2.blog-listing article.blog-post {
	border-top: 0;
	border-right: 0;
	border-bottom: 1px solid #e3e3e3;
	border-left: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	padding-bottom: 40px;
	margin-bottom: 40px
}
section#version2.blog-listing article.blog-post:last-of-type {
	border-bottom: 0;
	margin-bottom: 0
}
section#version2.blog-listing article.blog-post .blog-thumbnail {
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: linear-gradient(to right, #00a651 -20%, #443088 120%);
	padding: 0;
	overflow: hidden
}
section#version2.blog-listing article.blog-post .blog-thumbnail img {
	object-fit: cover;
	height: 350px;
	width: 100%;
	-webkit-transition: all .8s ease;
	-moz-transition: all .8s ease;
	-ms-transition: all .8s ease;
	-o-transition: all .8s ease;
	transition: all .8s ease
}
section#version2.blog-listing article.blog-post .blog-thumbnail:hover img {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
	-webkit-filter: blur(3px);
	-moz-filter: blur(3px);
	-ms-filter: blur(3px);
	-o-filter: blur(3px);
	filter: blur(3px);
	opacity: .4
}
#blog-comments .comments-list .commenter:before, section.shop .product-image:hover img {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2)
}
section#version2.blog-listing article.blog-post .blog-desc {
	margin-top: 40px
}
.blog-sidebar {
	background: #f0f0f0;
	padding: 40px 10px;
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
.blog-sidebar .widget-title {
	text-transform: uppercase;
	font-weight: 600
}
.blog-sidebar .widget-title:after {
	content: '';
	width: 50px;
	height: 3px;
	background: #e76e34;
	display: block;
	margin-top: 20px;
	margin-bottom: 40px
}
.blog-sidebar .sidebar-blog-post .thumbnail-post {
	width: 80px;
	height: 80px;
	overflow: hidden;
	position: relative;
	float: left;
	margin-right: 20px;
	margin-bottom: 30px;
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: linear-gradient(to right, #00a651 -20%, #443088 120%)
}
.blog-sidebar .sidebar-blog-post .thumbnail-post img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease
}
.blog-sidebar .sidebar-blog-post .thumbnail-post img:hover {
	opacity: .5
}
.blog-sidebar .sidebar-blog-post .post-info {
	padding-top: 15px
}
.blog-sidebar .sidebar-blog-post .post-info a {
	text-transform: capitalize;
	font-weight: 600;
	color: #333;
	display: block
}
.blog-sidebar .sidebar-blog-post .post-info a:hover {
	color: #00a651
}
.blog-sidebar .sidebar-blog-post .post-info span {
	font-style: italic
}
.blog-sidebar .mailchimp button {
	width: 100%
}
.blog-sidebar .tag-wrapper a {
	color: #999;
	font-size: 16px;
	background: #fff;
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 10px 15px;
	line-height: 50px;
	text-transform: capitalize;
	margin-right: 5px;
	white-space: nowrap
}
.blog-sidebar .tag-wrapper a:hover {
	background-color: #00a651;
	color: #fff
}
.blog-sidebar .sidebar-list {
	padding: 0
}
.blog-sidebar .sidebar-list li {
	padding: 8px 0;
	border-bottom: 1px solid #e3e3e3
}
.blog-sidebar .sidebar-list li:last-child {
	padding-bottom: 0;
	border-bottom: 0
}
.blog-sidebar .sidebar-list li a {
	font-size: 16px;
	text-transform: capitalize;
	color: #999
}
.blog-sidebar .sidebar-list li a:hover {
	color: #00a651;
	padding-left: 10px
}
section.blog-masonry {
	background: #f0f0f0
}
.blog-masonry .blog-grid {
	height: 100%
}
.blog-masonry .element {
	margin-bottom: 40px
}
.blog-masonry .blog-single {
	background-color: #fff;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px
}
.blog-masonry .blog-post-thumbnail {
	height: 200px;
	width: 100%;
	overflow: hidden;
	position: relative
}
.blog-masonry .blog-post-thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	-webkit-transition: all .8s ease;
	-moz-transition: all .8s ease;
	-ms-transition: all .8s ease;
	-o-transition: all .8s ease;
	transition: all .8s ease
}
.blog-masonry .blog-more-btn i, section.shop .product-descr h4 {
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease
}
.blog-masonry .blog-post-title {
	margin: 0 25px;
	border-bottom: 1px solid #eee
}
.blog-masonry .blog-post-title h3>a {
	font-size: 18px;
	text-transform: capitalize;
	color: #333;
	font-weight: 600;
	font-family: inherit
}
#blog-comments h4, section#blog-post h2, section#blog-post h4 {
	text-transform: uppercase
}
.blog-masonry .blog-post-title h3>a:hover {
	color: #00a651
}
.blog-masonry .blog-post-title p {
	font-size: 12px;
	letter-spacing: .5px
}
.blog-masonry .blog-post-title .blog-author {
	text-transform: uppercase;
	font-weight: 700
}
.blog-masonry .blog-post-title .blog-date:before {
	content: '|';
	padding-left: 7px;
	padding-right: 9px
}
.blog-masonry .blog-post-details {
	margin: 0 25px
}
.blog-more-btn {
	color: #333
}
.blog-masonry .blog-more-btn:focus, .blog-masonry .blog-more-btn:hover {
	text-decoration: none;
	outline: 0
}
.blog-masonry .blog-more-btn i {
	margin-left: 5px;
	transition: all .3s ease
}
.blog-masonry .blog-more-btn:hover i {
	margin-left: 10px;
	color: #00a651
}
.blog-masonry .blog-post-thumbnail.normal-post {
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: linear-gradient(to right, #00a651 -20%, #443088 120%)
}
.blog-masonry .blog-post-thumbnail.normal-post:hover img {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
	-webkit-filter: blur(3px);
	-moz-filter: blur(3px);
	-ms-filter: blur(3px);
	-o-filter: blur(3px);
	filter: blur(3px);
	opacity: .4
}
.blog-masonry .post-thumbnail-slider, .blog-masonry .post-thumbnail-slider .owl-item .item, .blog-masonry .post-thumbnail-slider .owl-stage-outer, .blog-masonry .post-thumbnail-slider .owl-stage-outer .owl-stage {
	height: 100%
}
.blog-masonry .post-thumbnail-slider .owl-item {
	position: relative;
	float: left;
	height: 100%
}
.blog-masonry .post-thumbnail-slider .owl-nav {
	position: absolute;
	width: 100%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%)
}
.blog-masonry .post-thumbnail-slider .owl-nav .owl-next, .blog-masonry .post-thumbnail-slider .owl-nav .owl-prev {
	font-size: 28px;
	color: #f6f6f6;
	display: inline-block;
	cursor: pointer
}
.blog-masonry .post-thumbnail-slider .owl-nav .owl-prev {
	padding-left: 15px
}
.blog-masonry .post-thumbnail-slider .owl-nav .owl-next {
	text-align: right;
	float: right;
	padding-right: 15px
}
.blog-masonry .quote-post {
	background: #00a651
}
.blog-masonry .quote-post blockquote {
	font-family: 'Varela Round', sans-serif;
	border: none;
	margin: 0;
	font-size: 18px;
	text-align: center
}
.blog-masonry .quote-post blockquote span {
	font-family: 'Varela Round', sans-serif;
	display: block
}
.blog-masonry .video-post {
	height: auto
}
section#blog-post .post-content-wrapper {
	padding: 0 40px
}
section#blog-post .post-content {
	margin-top: 40px
}
section#blog-post h4 {
	padding-bottom: 20px
}
section#blog-post p {
	line-height: 26px
}
section#blog-post .post-img {
	height: 300px;
	margin: 40px 0
}
section#blog-post .post-img img {
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	width: 100%;
	height: 100%;
	object-fit: cover
}
section#blog-post .post-detail {
	padding-top: 10px;
	color: #999
}
section#blog-post .post-detail span:after {
	content: '|';
	padding: 0 7px
}
section#blog-post .post-detail span:last-child:after {
	display: none
}
section#blog-post .post-detail span i {
	padding-right: 5px
}
section#blog-post .post-quote {
	background: #e76e34;
	color: #f6f6f6;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	text-align: center;
	margin: 40px 0
}
section#blog-post .post-quote blockquote {
	position: relative;
	margin: 0;
	padding: 60px;
	font-style: italic;
	font-size: 24px;
	border: 0
}
section#blog-post .post-quote blockquote:after, section#blog-post .post-quote blockquote:before {
	font-family: fontawesome;
	position: absolute;
	display: inline-block;
	color: #f6f6f6;
	font-size: 72px;
	font-style: normal;
	opacity: .2
}
section#blog-post .post-quote blockquote:before {
	content: '\f10d';
	float: left;
	left: 30px;
	top: 0
}
section#blog-post .post-quote blockquote:after {
	content: '\f10e';
	float: right;
	right: 30px;
	bottom: 0
}
section#blog-post .social-btn-roll {
	background: 0 0
}
#blog-comments .comments-list {
	list-style: none;
	padding: 0
}
#blog-comments .comments-list .comment {
	padding: 40px 0;
	border-bottom: 1px solid #e3e3e3
}
#blog-comments .comments-list .comment:last-child {
	border-bottom: 0
}
#blog-comments .comments-list .commenter {
	position: relative;
	width: 65px;
	height: 65px;
	margin-right: 30px
}
#blog-comments .comments-list .commenter:after, #blog-comments .comments-list .commenter:before {
	width: 65px;
	height: 65px;
	position: absolute;
	z-index: 0;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	top: 0;
	background: #e76e34;
	content: '';
	display: block
}
#blog-comments .comments-list .commenter:before {
	transform: scale(1.2);
	border-radius: 50%;
	opacity: .15
}
#blog-comments .comments-list .commenter:after {
	-webkit-transform: scale(1.4);
	-moz-transform: scale(1.4);
	-ms-transform: scale(1.4);
	-o-transform: scale(1.4);
	transform: scale(1.4);
	border-radius: 50%;
	opacity: .1
}
#blog-comments .comments-list .commenter img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	z-index: 2;
	position: relative
}
#blog-comments .comments-list .comment-body h6 {
	text-transform: capitalize;
	padding-bottom: 5px
}
#blog-comments .comments-list .comment-body .comment-reply {
	margin: -20px 20px 0 0
}
#blog-comments .comments-list .comment-body .comment-info {
	padding-bottom: 25px
}
#blog-comments .comments-list .comment-body span {
	font-size: 14px;
	color: #999
}
#blog-comments .comment-body {
	overflow: visible
}
#blog-comments .comments-list .comment-replies {
	list-style: none;
	padding: 0;
	margin-top: 40px
}
#blog-comments .comments-list .comment-replied {
	padding-bottom: 40px
}
#blog-comments .comments-list .comment-replied:last-child {
	padding-bottom: 0
}
form#comment-form textarea {
	resize: none
}
section#next-post {
	position: relative;
	/*background: url(../images/blog/blog2.jpg) center no-repeat;*/
	background-size: cover;
	text-align: center;
	color: #f6f6f6
}
section#next-post:after {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	background: #000;
	position: absolute;
	top: 0;
	opacity: .7;
	z-index: 0
}
section#next-post>div {
	position: relative;
	z-index: 1
}
section#next-post span {
	font-size: 18px
}
section#next-post span:after {
	content: '';
	width: 50px;
	height: 3px;
	background: #e76e34;
	display: block;
	margin: 20px auto 0
}
section.shop {
	background: #f0f0f0
}
section.shop .product {
	margin-bottom: 40px;
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	overflow: hidden;
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease
}
section.shop .product:hover {
	-webkit-box-shadow: 5px 5px 30px 0 rgba(55,71,79,.1);
	-moz-box-shadow: 5px 5px 30px 0 rgba(55,71,79,.1);
	box-shadow: 5px 5px 30px 0 rgba(55,71,79,.1)
}
section.shop .product-image {
	position: relative;
	overflow: hidden;
	height: 260px;
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: linear-gradient(to right, #00a651 -20%, #443088 120%)
}
section.shop .product-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	-webkit-transition: all .8s ease;
	-moz-transition: all .8s ease;
	-ms-transition: all .8s ease;
	-o-transition: all .8s ease;
	transition: all .8s ease
}
section.shop .product-image:hover img {
	transform: scale(1.2);
	-webkit-filter: blur(3px);
	-moz-filter: blur(3px);
	-ms-filter: blur(3px);
	-o-filter: blur(3px);
	filter: blur(3px);
	opacity: .4
}
section.shop .product-image .product-overlay {
	position: absolute;
	width: 100%;
	height: 40px;
	line-height: 40px;
	top: auto;
	bottom: -40px;
	z-index: 6;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease
}
section.shop .product-image:hover .product-overlay {
	bottom: 0;
	opacity: 1;
	visibility: visible
}
section.shop .product-image .product-overlay a {
	display: block;
	width: 100%;
	font-size: 14px;
	color: #f6f6f6;
	background: #443088;
	text-align: center
}
section.shop .product-image .product-overlay a:hover {
	background: #00a651
}
section.shop .product-image .product-overlay a i {
	padding-right: 5px
}
section.shop .product-descr {
	background: #fff;
	text-align: center;
	padding: 20px 0
}
section.shop .product-descr h4 {
	color: #323232;
	transition: all .3s ease
}
section.shop .product-descr h4:hover {
	color: #00a651
}
section.shop .product-descr h5 {
	color: #323232;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease
}
section.shop .product-descr h5:hover {
	color: #00a651
}
section.shop .product-descr span.price {
	color: #999;
	font-size: 18px
}
section.shop .pagination {
	margin-top: 20px
}
.shop-sidebar {
	padding: 0 10px
}
.shop-sidebar .widget-title {
	text-transform: uppercase;
	font-weight: 600
}
.shop-sidebar .widget-title:after {
	content: '';
	width: 50px;
	height: 3px;
	background: #e76e34;
	display: block;
	margin-top: 20px;
	margin-bottom: 40px
}
.shop-sidebar .cart-sidebar span {
	font-style: italic;
	color: #999
}
.shop-sidebar .price-filter .ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all {
	background: #fff;
	border: none;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	height: 10px
}
.shop-sidebar .price-filter .ui-slider-handle.ui-state-default.ui-corner-all {
	background: #fff;
	border: 3px solid #e76e34;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	height: 21px;
	margin-top: -2px;
	width: 21px;
	outline: 0
}
.shop-sidebar .price-filter .ui-slider-range {
	background: #e76e34
}
.shop-sidebar .price-slider-amount>input[type=text] {
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	font-weight: 700;
	height: 40px;
	letter-spacing: 1px;
	text-align: center;
	width: 65%;
	box-shadow: none!important;
	-webkit-appearance: none
}
.shop-sidebar .price-slider-amount>button {
	float: right;
	font-weight: 700;
	height: 40px;
	letter-spacing: 1px;
	text-transform: uppercase;
	width: 30%
}
.shop-sidebar .sidebar-product .thumbnail-post {
	width: 80px;
	height: 80px;
	overflow: hidden;
	position: relative;
	float: left;
	margin-right: 20px;
	margin-bottom: 30px;
	background: -moz-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: -webkit-linear-gradient(left, #00a651 -20%, #443088 120%);
	background: linear-gradient(to right, #00a651 -20%, #443088 120%)
}
.shop-sidebar .sidebar-product .thumbnail-post img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	-webkit-transition: all .6s ease;
	-moz-transition: all .6s ease;
	-ms-transition: all .6s ease;
	-o-transition: all .6s ease;
	transition: all .6s ease
}
.shop-sidebar .sidebar-product .thumbnail-post img:hover {
	opacity: .5
}
.shop-sidebar .sidebar-product .product-info {
	padding-top: 15px
}
.shop-sidebar .sidebar-product .product-info a {
	text-transform: capitalize;
	font-weight: 600;
	color: #333;
	display: block
}
.shop-sidebar .sidebar-product .product-info a:hover {
	color: #00a651
}
.shop-sidebar .sidebar-product .product-info span {
	color: #999;
	font-style: italic
}
.shop-sidebar .sidebar-list {
	padding: 0
}
.shop-sidebar .sidebar-list li {
	padding: 8px 0;
	border-bottom: 1px solid #e3e3e3
}
.shop-sidebar .sidebar-list li:last-child {
	padding-bottom: 0;
	border-bottom: 0
}
.shop-sidebar .sidebar-list li a {
	font-size: 16px;
	text-transform: capitalize;
	color: #999
}
.shop-sidebar .sidebar-list li a:hover {
	color: #00a651;
	padding-left: 10px
}
.product-slider .item>div {
	height: 350px;
	overflow: hidden
}
.product-slider .item>div img {
	width: 100%;
	height: 100%;
	object-fit: cover
}
.product-slider .owl-nav.disabled+.owl-dots {
	text-align: center;
	margin-top: 10px
}
.product-slider .owl-dots .owl-dot {
	display: inline-block;
	zoom: 1
}
.product-slider .owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	margin: 5px 7px;
	background: #ddd;
	display: block;
	-webkit-backface-visibility: visible;
	-webkit-transition: all .2s ease;
	-moz-transition: all .2s ease;
	-ms-transition: all .2s ease;
	-o-transition: all .2s ease;
	transition: all .2s ease;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%
}
.product-slider .owl-dots .owl-dot.active span {
	background: #00a651
}
.product-details .product-rating i {
	color: #fdc948;
	font-size: 18px
}
.product-details .product-price {
	padding: 20px 0;
	font-size: 18px
}
.product-details .product-price del {
	color: #999;
	padding-right: 10px;
	font-style: italic
}
.product-details .product-price span.price {
	font-size: 24px;
	font-weight: 600
}
.product-quantity {
	display: inline-block;
	margin: 0;
	float: none;
	border: 1px solid #e3e3e3
}
.product-quantity .minus, .product-quantity .plus {
	display: block;
	float: left;
	cursor: pointer;
	border: 0 transparent;
	padding: 0;
	width: 36px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	background-color: #EEE;
	font-size: 16px;
	font-weight: 700
}
.product-quantity .qty {
	float: left;
	width: 50px;
	line-height: 40px;
	border: 0;
	border-left: 1px solid #e3e3e3;
	border-right: 1px solid #e3e3e3;
	background-color: #f6f6f6;
	text-align: center;
	margin-bottom: 0
}
.product-wrapper .nav-tabs>li {
	text-align: center
}
.product-wrapper .nav-tabs>li.active>a {
	color: #333;
	border-top: 3px solid #00a651
}
.product-wrapper .nav-tabs>li.active>a:hover {
	background: #fff;
	border-top: 3px solid #00a651;
	border-right: 1px solid #e3e3e3;
	border-bottom: 1px solid #fff;
	border-left: 1px solid #e3e3e3
}
.product-wrapper .nav-tabs>li>a {
	padding: 15px 30px;
	color: #666;
	border-top: 3px solid transparent
}
.product-wrapper .nav-tabs>li>a:hover {
	background: 0 0;
	border: 1px solid transparent;
	border-top: 3px solid transparent
}
.product-wrapper .nav-tabs>li>a:focus {
	color: #333!important
}
.product-wrapper .tab-content {
	background: #fff;
	border: 1px solid #e3e3e3;
	border-top: 0;
	-webkit-border-radius: 0 0 10px 10px;
	-moz-border-radius: 0 0 10px 10px;
	border-radius: 0 0 10px 10px
}
.product-wrapper .tab-content .tab-pane {
	padding: 40px
}
.product-wrapper h4 {
	text-transform: uppercase
}
.product-wrapper .comments-list {
	list-style: none;
	padding: 0
}
.product-wrapper .comments-list .comment {
	padding: 40px 0;
	border-bottom: 1px solid #e3e3e3
}
.product-wrapper .comments-list .comment:last-child {
	border-bottom: 0
}
.product-wrapper .comments-list .commenter {
	position: relative;
	width: 65px;
	height: 65px;
	margin-right: 30px
}
.product-wrapper .comments-list .commenter:after, .product-wrapper .comments-list .commenter:before {
	content: '';
	display: block;
	background: #e76e34;
	width: 65px;
	height: 65px;
	position: absolute;
	top: 0
}
.product-wrapper .comments-list .commenter:before {
	z-index: 0;
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	opacity: .15
}
.product-wrapper .comments-list .commenter:after {
	z-index: 1;
	-webkit-transform: scale(1.4);
	-moz-transform: scale(1.4);
	-ms-transform: scale(1.4);
	-o-transform: scale(1.4);
	transform: scale(1.4);
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	opacity: .1
}
.product-wrapper .comments-list .commenter img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	position: relative;
	z-index: 2
}
.product-wrapper .comments-list .comment-body h6 {
	text-transform: capitalize;
	padding-bottom: 5px
}
.product-wrapper .comments-list .comment-body .comment-rating {
	margin: -20px 20px 0 0;
	color: #fdc948;
	font-size: 18px
}
.product-wrapper .comments-list .comment-body .comment-info {
	padding-bottom: 25px
}
.product-wrapper .comments-list .comment-body span {
	font-size: 14px;
	color: #999
}
.product-wrapper form textarea {
	padding: 10px 20px;
	box-shadow: none;
	max-width: 100%;
	resize: none;
	color: #999
}
.cart {
	overflow: hidden
}
.cart>tbody>.cart-item>td, .cart>tbody>.cart-item>th, .cart>tfoot>tr>td, .cart>tfoot>tr>th, .cart>thead>tr>td, .cart>thead>tr>th {
	vertical-align: middle!important;
	text-align: center
}
.cart .cart-item a {
	font-weight: 600;
	color: #323232
}
.cart .cart-item a:hover {
	color: #00a651
}
.cart .cart-item .cart-product-thumbnail img {
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease
}
.cart .cart-item .cart-product-thumbnail img:hover {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%
}
.cart-product-quantity .quantity {
	display: inline-block;
	margin: 0;
	float: none;
	border: 1px solid #e3e3e3
}
.cart .quantity .minus, .cart .quantity .plus {
	display: block;
	float: left;
	cursor: pointer;
	border: 0 transparent;
	padding: 0;
	width: 36px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	background-color: #EEE;
	font-size: 16px;
	font-weight: 700
}
.cart .quantity .qty {
	float: left;
	width: 50px;
	line-height: 40px;
	border: 0;
	border-left: 1px solid #e3e3e3;
	border-right: 1px solid #e3e3e3;
	background-color: #f6f6f6;
	text-align: center;
	margin-bottom: 0
}
section.shop .panel-group .panel+.panel {
	margin-top: 10px
}
section.shop .panel-group .panel {
	border: 1px solid #e3e3e3;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	box-shadow: none
}
section.shop .panel-group .panel .panel-heading {
	padding: 15px 20px
}
section.shop .panel-group .panel .panel-heading h4 a {
	font-family: inherit;
	font-size: 18px
}

.job-desc{width:100%; float:left; border:1px #ccc solid;}
.job-list{width:100%; float:left; border-bottom:1px #ccc solid;}
.job-list h4{margin:10px; padding:15px 10px 15px 10px; font-size: 20px;}
.job-list h6{margin:10px; padding:15px 10px 15px 10px; font-size: 18px;}
.job-list ul{margin:0px; padding:0px;}
.job-list ul li{margin:0px; padding:5px 10px 5px 15px;}
.bdr-btm_n{ border-bottom:none !important;}


.main-job-desc{width:100%; float:left;}
.hr_row{width:100%; float:left; margin-bottom:10px;}

.main-job-details{width:100%; border:1px #ccc solid; margin-top:10px;}
.main-job-details .blog-desc{border-bottom:1px #ccc solid;}
.main-job-details .blog-desc h5{margin:10px 0px 0px 0px !important;}
.post-detail {width: 100%;float: left;}
.job-date{background: #f5f5f5; width: 100%; float: left; margin:5px 0; border:1px #ccc solid; padding: 4px 8px;}
.job-date h4{float:left; font-size:14px; display:inline-block; line-height: 28px;}
.job-date p{float:right; font-size:14px; margin:0px !important; display:inline-block;}
.main-job-details .blog-desc .post-detail span{margin-right:10px !important; font-size: 16px;}
.job-btn{text-align:left; margin: 5px 0;}

.main-job-desc .bootstrap-select > .dropdown-toggle, .main-job-desc .bootstrap-select > .dropdown-toggle.bs-placeholder{height:40px; border: 1px solid #e3e3e3;  padding-left: 10px;}
.job-list .bootstrap-select > .dropdown-toggle, .job-list .bootstrap-select > .dropdown-toggle.bs-placeholder{height:40px; border: 1px solid #e3e3e3;  padding-left: 10px;}


.profile-section{width: 100%; float: left; border-bottom: 1px #ccc solid; padding: 6px; margin-top: 10px;}
.profile-section .form-group{width: 100%; float: left; margin-bottom: 10px;}
.profile-section .form-group label{padding: 8px 0px; margin-bottom: 0px;}
.profile-section .bootstrap-select > .dropdown-toggle, .profile-section .bootstrap-select > .dropdown-toggle.bs-placeholder{height:40px;border: 1px solid #e3e3e3}
.article-align { text-align: justify;}
.main-job-details1{width:100%; border:1px #ccc solid; margin-top:10px; float: left;}
.job_holder{width: 100%; float: left; margin-bottom: 10px;padding: 12px 8px; border-bottom: 1px #ccc solid}
.job_holder h5{margin-bottom: 10px !important;}
.job_holder ul {padding: 0px; margin: 0px;}
.job_holder ul li{float:left; width: 33%;}
.label_space label{margin-right: 8px;}
.profile-section span{line-height: 36px;}
.profile-section a{line-height: 36px;}
.form-group{width: 100%; float: left; margin-bottom: 10px;}
.label_height label{padding: 8px 0; margin-bottom: 0px;}

/* BY SJ */
.emp_job_holder{width: 100%; float: left; margin-bottom: 10px;padding: 12px 8px; border-bottom: 1px #ccc solid}
.emp_job_holder h5{margin-bottom: 10px !important;}
.emp_job_holder ul {padding: 0px; margin: 0px;}
.emp_job_holder ul li{float:left; width: 25%;}
.emp_job_holder ul li a:hover{color:#666;}
.emp_manage_resume_holder ul li,h5 a:hover{color:#666;}
/* */

label.btn span {
   color: #333;
    font-size: 14px;
}

label input[type="radio"] ~ i.fa.fa-circle-o{
    color: #c8c8c8;    display: inline; font-size: 20px; margin-right: 5px;
}
label input[type="radio"] ~ i.fa.fa-dot-circle-o{
    display: none;
}
label input[type="radio"]:checked ~ i.fa.fa-circle-o{
    display: none;
}
label input[type="radio"]:checked ~ i.fa.fa-dot-circle-o{
    color: #7AA3CC;    display: inline; font-size: 20px; margin-right: 5px; }
label:hover input[type="radio"] ~ i.fa {
color: #7AA3CC;
}

label input[type="checkbox"] ~ i.fa.fa-square-o{
    color: #c8c8c8;    display: inline;
}
label input[type="checkbox"] ~ i.fa.fa-check-square-o{
    display: none;
}
label input[type="checkbox"]:checked ~ i.fa.fa-square-o{
    display: none;
}
label input[type="checkbox"]:checked ~ i.fa.fa-check-square-o{
    color: #7AA3CC;    display: inline;
}
label:hover input[type="checkbox"] ~ i.fa {
color: #7AA3CC;
}

div[data-toggle="buttons"] label.active{
    color: #7AA3CC;
}

div[data-toggle="buttons"] label {
display: inline-block;
padding: 6px 12px;
margin-bottom: 0;
font-size: 14px;
font-weight: normal;
line-height: 2em;
text-align: left;
white-space: nowrap;
vertical-align: top;
cursor: pointer;
background-color: none;
border: 0px solid 
#c8c8c8;
border-radius: 3px;
color: #c8c8c8;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-o-user-select: none;
user-select: none;
}

div[data-toggle="buttons"] label:hover {
color: #7AA3CC;
}

div[data-toggle="buttons"] label:active, div[data-toggle="buttons"] label.active {
-webkit-box-shadow: none;
box-shadow: none;
}

.bar_label label{float: left; margin-bottom: 0px; padding: 12px 0;}
.bar_label .form-control{float: right; width:75%;}

.tabel-wrap{width: 100%; overflow-x: auto}
.table {min-width: 500px; margin-bottom: 0px; width: 100%;}
.table table{width: 100%;}
.table table tr{ border-bottom: 1px #ccc solid; }
.table table tr:last-child{ border-bottom:none }
.table table tr td{border-right:1px #ccc solid; padding: 4px 6px;}
.jobseeker_sub_component_table .table table tr td{border-right: none;}
.table table tr td:last-child{border-right: none;}

/* By SJ*/
.table table tr td,a:hover{color:#666;}
 .tablecheckbox table tr td{ height: 42px;
    text-align: left;}
/* By SJ */

.table table tr:nth-child(even) {background: #f3f3f3}
.table table  tr:nth-child(odd) {background: #FFF}

.admin_section{width: 100%; float: left; border:1px #ccc solid; margin:10px 0;}
.admin_section .admin_head{background: #f3f3f3; display: block; padding: 6px 8px; font-size: 16px; columns: #333; border-bottom: 1px #ccc solid;}
.admin_section .admin_content{padding: 15px; min-height: 150px;}
.admin_content .admin_dashboard{font-size:48px;}

.main_heading{width: 100%; border-bottom: 1px #ccc solid; float: left; padding-bottom: 6px; background: #f5f5f5;  padding:8px;}
.list_content{width: 100%; float: left; padding:8px;}


.packages_cont{width:100%; margin:0px auto;}
.package_detail{width:100%; float: left; min-height: 450px; margin:30px 0;}
.package_detail .package_head{width:100%; float: left; background: #00a4b4; padding: 30px; position: relative;}
.package_detail .package_head h3{font-weight: bold; color: #fff; text-align: center; margin:0;}
.package_head:before{ content: "";position: absolute;bottom: -14px;left: 20%;  margin-left: -7px;border-left: 14px solid transparent; border-right: 14px solid transparent;border-top: 14px solid #00a4b4;}
.package_detail .price_cont{width:100%; float: left; padding: 30px;text-align: center; position: relative;}
.package_detail .price_cont span{position: absolute; left: 22px; top:35px; font-size: 24px; color: #7c7c7c;}
.package_detail .price_cont h1{font-size:36px; color: #7c7c7c; margin:0;}
.package_detail .price_cont h6{font-size:18px; color: #7c7c7c; margin:0; line-height:5px;}
.packages_list{width: 100%; float: left;}
.packages_list ul{background: #f4f4f4; width: 100%; margin:0px; padding:25px 0;}
.packages_list ul li{border-bottom: 1px #e2e2e2 solid; width: 100%; padding:7px 20px;}
.package_footer{width: 100%; float: left; background: #f4f4f4; padding: 10px 10px 20px 10px; text-align: center;}
.package_footer .btn-yellow{background:#fec700;color:#333;border-radius:30px;text-align:center;padding:8px;width:50%;border:none;font-weight:bold;}

.package_detail .package_head1{width:100%; float: left; background:#CD7F32; padding: 30px; position: relative;}
.package_head1:before{ content: "";position: absolute;bottom: -14px;left: 20%;  margin-left: -7px;border-left: 14px solid transparent; border-right: 14px solid transparent;border-top: 14px solid #CD7F32;}

.package_detail .package_head2{width:100%; float: left; background:#A9A9A9; padding: 30px; position: relative;}
.package_head2:before{ content: "";position: absolute;bottom: -14px;left: 20%;  margin-left: -7px;border-left: 14px solid transparent; border-right: 14px solid transparent;border-top: 14px solid #A9A9A9;}

.package_detail .package_head3{width:100%; float: left; background:#fec700; padding: 30px; position: relative;}
.package_head3:before{ content: "";position: absolute;bottom: -14px;left: 20%;  margin-left: -7px;border-left: 14px solid transparent; border-right: 14px solid transparent;border-top: 14px solid #fec700;}

.package_footer .Bronze{background:#CD7F32;color:#333;border-radius:30px;text-align:center;padding:8px;width:50%;border:none;font-weight:bold;}
.package_footer .Silver{background:#A9A9A9;color:#333;border-radius:30px;text-align:center;padding:8px;width:50%;border:none;font-weight:bold;}
/***** for Home clock *****/
.slogan{
	font-family: 'Varela Round', sans-serif;
    font-size: 45px;
    margin-left: 50px;
    font-weight:  bold;
	}
.slogan span{
	font-family: 'Varela Round', sans-serif;
    color: green;
}
.wraper-height{
	top:60%;
}
/***** end for Home clock *****/
.label_field h6{position:relative; top:-8px; left:25px;}

@media screen and (max-width: 767px) and (min-width: 320px) {
	.packages_cont{width:100%; margin:0px auto;}
	.package_detail .price_cont span{position: relative; font-size: 24px; left: 0; top: 0px;}

}

/********************************/
/*          Main CSS     */
/********************************/


#first-slider .main-container {
  padding: 0;
}


#first-slider .slide1 h3, #first-slider .slide2 h3, #first-slider .slide3 h3, #first-slider .slide4 h3{
    color: #fff;
    font-size: 30px;
    text-transform: uppercase;
	font-weight:700;
}

#first-slider .slide1, #first-slider .slide2{padding:75px 50px;}
#first-slider .slide1 h4,#first-slider .slide2 h4,#first-slider .slide3 h4,#first-slider .slide4 h4{
    color: #fff;
    font-size: 30px;
    text-transform: uppercase;
    font-weight:700;
}
#first-slider .slide1 .text-left ,#first-slider .slide3 .text-left{
    padding-left: 40px;
}


#first-slider .carousel-indicators {
  bottom: 0;
}
#first-slider .carousel-control.right,
#first-slider .carousel-control.left {
  background-image: none;
}
#first-slider .carousel .item {
  min-height: 546px; 
  height: 100%;
  width:100%;
}

.carousel-inner .item .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}


#first-slider h3{
  animation-delay: 1s;
}
#first-slider h4 {
  animation-delay: 2s;
}
#first-slider h2 {
  animation-delay: 3s;
}


#first-slider .carousel-control {
    width: 6%;
        text-shadow: none;
}


#first-slider h1 {
  text-align: center;  
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
}

#first-slider .p {
  padding-top: 125px;
  text-align: center;
}

#first-slider .p a {
  text-decoration: underline;
}
#first-slider .carousel-indicators li {
    width: 14px;
    height: 14px;
    background-color: rgba(255,255,255,.4);
    border:none;
}
#first-slider .carousel-indicators .active{
    width: 16px;
    height: 16px;
    background-color: #fff;
    border:none;
}

/********************************/
/*       Slides backgrounds     */
/********************************/
#first-slider .slide1 {
	/*background: url(../images/img/image03.jpg) center no-repeat;*/
	background-size: cover;
	background-repeat: no-repeat;
}
#first-slider .slide2 {
	/*background: url(../images/img/image04.jpg) center no-repeat;*/
	background-size: cover;
	background-repeat: no-repeat;
}

ul.ngx-pagination{
	display: table;
	margin: 30px auto;
}
ul.ngx-pagination li{
	margin: 0 10px;
    font-size: 1.2em;
    background: #fff;
    border: 2px solid #e76e34;
    background-color: #e76e34;
    border-radius: 5px;
}

ul.ngx-pagination li a{
	color: #fff;
	padding: 5px 7px;
}
ul.ngx-pagination li a:hover{
	color: #e76e34;
	background: #fff;
}
ul.ngx-pagination li a span{
	padding: 5px 7px;
}
ul.ngx-pagination li.disabled
{
	color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
}
ul.ngx-pagination li.current{
	font-size: 1.2em;
    padding: 6px 15px;
	background: 0 0;
    color: #e76e34;
    border-color: #e76e34;
}

.employer-search .btn{
	float: left;
    padding: 0 7px;
    margin: 7px;
}

.tablecheckbox table tr td input[type='checkbox'] {
	position: relative;
	left: 0;
}
.jobapplicationTable table tr td select{
	width: 133px;
}

.jobappSelect{
	padding: 6px;
    margin: 0 auto 10px auto;
	width: 133px;
}

#sendMailModal .modal-body{display: flow-root;}
.credit_card_img_transparent {
    opacity: 0.2;
}

form#buypackageForm input.has-error {
	border: 1px solid #d76666;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;
}

form#buypackageForm input.has-success {
	border: 1px solid #3c763d;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

/* For Card UI */
.card-block{
	margin-left: 15px; 
	margin-right: 15px;
}
.card-block img{
	height:150px;
	width:100%;
  }
  
  div [class^="col-"]{
	padding-left:5px;
	padding-right:5px;
  }
  .card{
	transition:0.5s;
	cursor:pointer;
  }
  .card-title{  
	padding-top: 3px;
	font-size:15px;
	transition:1s;
	cursor:pointer;
  }
  .card-title i{  
	font-size:15px;
	transition:1s;
	cursor:pointer;
	color:#ffa710
  }
  .card-title i:hover{
	transform: scale(1.25) rotate(100deg); 
	color:#18d4ca;
	
  }
  .card:hover{
	transform: scale(1.05);
	box-shadow: 10px 10px 15px rgba(0,0,0,0.3);
  }
  .card-text{
	height:80px;  
  }
  
  .card::before, .card::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transform: scale3d(0, 0, 1);
	transition: transform .3s ease-out 0s;
	background: rgba(255, 255, 255, 0.1);
	content: '';
	pointer-events: none;
  }
  .card::before {
	transform-origin: left top;
  }
  .card::after {
	transform-origin: right bottom;
  }
  .card:hover::before, .card:hover::after, .card:focus::before, .card:focus::after {
	transform: scale3d(1, 1, 1);
  }